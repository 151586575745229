import React, { useEffect } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography, Button } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import User from '../../../interfaces/users.interface';
import { Question } from '../../../interfaces/question.interface';
import RemediationValidation from './RemediationValidation';
import FileUploaderComp, { FileUpload } from '../../Forms/FileUpload';
import { NOT_IMPLEMENTED, PARTIALLY_IMPLEMENTED, REMEDIATION_OPTIONS, REMEDIATION_VERIFICATION_STATUS } from '../../../services/surveyHelpers';
import moment from 'moment';
import { SubRemediation, WillNotRemediateReasons } from '../../../interfaces/assessment.interface';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RemediationFormStep from './RemediationFormStep';
import StepRemediationStatusIndicator from './StepRemediationStatusIndicator';
import { useAppSelector } from '../../../app/hooks';
import WillNotRemediateReasonSelect from './WillNotReasonSelect';
import { USER_TYPES } from '../../../services/user';

type RemediationFormProps = {
  stepIndex: number;
  remediationDateErr: string;
  subRemErr: string;
  user: User | undefined;
  question: Question;
  remediationComment: string;
  willNotRemediateReason?: WillNotRemediateReasons;
  remediationDate: string | null;
  vendorStatus: string;
  vfiles: FileUpload[];
  errorMessage: string;
  origRemediationDate?: string;
  origRemediationStatus?: string;
  origVerificationDate?: string;
  origVerificationStatus?: string;
  origVerificationComment?: string;
  origSubRemediation?: SubRemediation;
  assessorAnswer?: string;
  subRemediations: SubRemediation[];
  subRemVFiles: { [key: string]: FileUpload[] };
  handleStep: (newStep: number) => void;
  handleRemediationModalClose: () => void;
  handleWillNotRemediateReasonChange: (reason: WillNotRemediateReasons) => void;
  setRemediationDateErr: (err: string) => void;
  onVFilesChange: (newVfiles: FileUpload[]) => void;
  setSubRemediations: (newSubRemediation: SubRemediation[]) => void;
  onVendorStatusChange: (newVendorStatus: string) => void;
  onRemediationCommentChange: (newRemediationComment: string) => void;
  onRemediationDateChange: (newRemediationDate: string | null) => void;
  onSubRemVFilesChange: (newSubRemVFiles: { [key: string]: FileUpload[] }) => void;
  patchRemediationValidationStatus: (newValidationStatus: string, isSubRemediation: boolean, comment: string) => void;
};

const RemediationForm = (props: RemediationFormProps) => {
  const {
    handleStep,
    stepIndex,
    subRemErr,
    subRemediations,
    subRemVFiles,
    assessorAnswer,
    willNotRemediateReason,
    user,
    question,
    remediationComment,
    remediationDate,
    vendorStatus,
    vfiles,
    errorMessage,
    origRemediationDate,
    origRemediationStatus,
    origVerificationDate,
    origVerificationStatus,
    origVerificationComment,
    origSubRemediation,
    onSubRemVFilesChange,
    setSubRemediations,
    remediationDateErr,
    onRemediationCommentChange,
    onRemediationDateChange,
    onVendorStatusChange,
    onVFilesChange,
    setRemediationDateErr,
    patchRemediationValidationStatus,
    handleWillNotRemediateReasonChange,
  } = props;
  const currSubRemediation = React.useMemo(() => subRemediations[stepIndex] || {}, [subRemediations, stepIndex]);
  const propSubRemediation = React.useMemo(() => subRemediations[stepIndex] || {}, [subRemediations, stepIndex]);
  const { assessment, bpVerificationList } = useAppSelector(state => state.assessments);
  const handleRemediationCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onRemediationCommentChange(e.target.value);
  };

  const lapsedWillRemediatePlan = React.useMemo(() =>
    vendorStatus === REMEDIATION_OPTIONS.willRemediateLater &&
  !!remediationDate &&
  moment(remediationDate).isBefore(moment(), 'day') &&
  assessment?.notifications?.some((notification) => notification.question === question.id), [assessment, question, vendorStatus, remediationDate]);

  const handleRemediationDateChange = (newRemediationDate: string | null) => {
    if (vendorStatus === REMEDIATION_OPTIONS.remediated && newRemediationDate && moment(newRemediationDate).isAfter(moment(), 'day')) {
      setRemediationDateErr('Remediated status requires a current or past date');
    } else {
      setRemediationDateErr('');
    }
    onRemediationDateChange(newRemediationDate);
  };

  const handleVendorStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemediationDateErr('');
    if (e.target.value === REMEDIATION_OPTIONS.willNotRemediate) {
      onRemediationDateChange(!origRemediationDate ? new Date().toISOString() : new Date(origRemediationDate).toISOString());
    }
    onVendorStatusChange(e.target.value);
  };

  const handleSetSubRemediations = React.useCallback((newSubRemediation: SubRemediation) => {
    const newSubRemediations = [...subRemediations];
    newSubRemediations[stepIndex] = newSubRemediation;
    setSubRemediations(newSubRemediations);
  }, [subRemediations, stepIndex, setSubRemediations]);

  const [stepErr, setStepErr] = React.useState<string>('');

  useEffect(() => {
    setStepErr('');
  }, [stepIndex]);

  const handlePrevStep = React.useCallback(() => {
    setStepErr('');
    handleStep(stepIndex - 1);
  }, [currSubRemediation, subRemediations, stepIndex]);

  const handleNextStep = React.useCallback(() => {
    setStepErr('');
    handleStep(stepIndex + 1);
  }, [currSubRemediation, subRemediations, stepIndex]);

  const verificationDisable = React.useMemo(() => (!!user && user.type !== USER_TYPES.VENDOR_ADMIN) || origVerificationStatus === REMEDIATION_VERIFICATION_STATUS.reviewed, [user, origVerificationStatus]);
  const needsVerification = React.useMemo(() => {
    const bpCpy = question.bestPractice;
    return typeof bpCpy === 'object' &&
           bpVerificationList?.some(bp => bp.bestpracticeId === bpCpy.id);
  }, [bpVerificationList, question.bestPractice]);
  const showSubRemediation = React.useMemo(() => {
    return [PARTIALLY_IMPLEMENTED, NOT_IMPLEMENTED].includes(assessorAnswer || '') && !!subRemediations?.length;
  }, [assessorAnswer, subRemediations]);

  useEffect(() => {
    if (
      vendorStatus === REMEDIATION_OPTIONS.remediated &&
      remediationDate &&
      moment().isBefore(moment(remediationDate), 'day')
    ) {
      handleRemediationDateChange(null);
    }
  }, [vendorStatus]);

  return (
    <>
      { (showSubRemediation) ? (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb:1 }}>
          <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#424242' }}>
           Remediation {stepIndex + 1}/{subRemediations.length}
          </Typography>
          <StepRemediationStatusIndicator subRemediations={subRemediations} subRemVFiles={subRemVFiles}/>
        </Box>
      <Typography variant='h6'>
         {currSubRemediation.text}
      </Typography>
      <RemediationFormStep
        question={question}
        onSubRemVFilesChange={onSubRemVFilesChange}
        user={user}
        subRemErr={subRemErr}
        fileList={subRemVFiles[currSubRemediation.answer as number] || []}
        errorMessage={stepErr}
        handleSetSubRemediations={handleSetSubRemediations}
        patchRemediationValidationStatus={patchRemediationValidationStatus}
        subRemediation={propSubRemediation}
        origSubRemediation={origSubRemediation}
      />
      <Box sx={{ display: 'flex', width: '100%', marginTop: '20px', justifyContent: 'flex-end', gap:1, alignItems: 'center' }}>
        <Button 
          onClick={handlePrevStep}
          disabled={stepIndex === 0} variant='outlined' color='primary'>
          <NavigateBeforeIcon />
          Previous
        </Button>
        <Button
          onClick={handleNextStep}
          disabled={stepIndex === subRemediations.length - 1} variant='contained' color='primary'>
          Next
          <NavigateNextIcon />
        </Button>
      </Box>
      </>
      ) : (
      <>
        <RadioGroup
          aria-labelledby={question.title}
          name='assessor-answer'
          value={vendorStatus}
          onChange={handleVendorStatusChange}
        >
          <FormControlLabel
            disabled={verificationDisable}
            value={REMEDIATION_OPTIONS.willNotRemediate}
            control={<Radio />}
            label={
            <Typography sx={{ fontWeight: vendorStatus === REMEDIATION_OPTIONS.willNotRemediate ? 'bold' : 'inherit' }}>
              Will not Remediate
            </Typography>
          }
          />
          <FormControlLabel
            disabled={verificationDisable}
            value={REMEDIATION_OPTIONS.willRemediateLater}
            control={<Radio />}
            label={
              <Typography sx={{ fontWeight: vendorStatus === REMEDIATION_OPTIONS.willRemediateLater ? 'bold' : 'inherit' }}>
                Will Remediate Later
              </Typography>
            }
          />
          <FormControlLabel
            disabled={verificationDisable}
            value={REMEDIATION_OPTIONS.remediated}
            control={<Radio />}
            label={
              <Typography sx={{ fontWeight: vendorStatus === REMEDIATION_OPTIONS.remediated ? 'bold' : 'inherit' }}>Remediated</Typography>
            }
          />
        </RadioGroup>
        { vendorStatus === REMEDIATION_OPTIONS.remediated && needsVerification && (
          <RemediationValidation
            origVerificationStatus={origVerificationStatus}
            origRemediationStatus={origRemediationStatus}
            origVerificationComment={origVerificationComment}
            origVerificationDate={origVerificationDate}
            patchRemediationValidationStatus={patchRemediationValidationStatus}
          />
        )}
        <Box sx={{ mt: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label='Remediation Date'
              inputFormat='MM/DD/yyyy'
              value={remediationDate}
              onChange={handleRemediationDateChange}
              shouldDisableDate={(date) => {
                // Disable future dates if plan is Remediated
                return vendorStatus === REMEDIATION_OPTIONS.remediated && new Date(date) > new Date();
              }}
              disabled={vendorStatus === REMEDIATION_OPTIONS.willNotRemediate || (user && user.type !== USER_TYPES.VENDOR_ADMIN) || !vendorStatus || verificationDisable}
              renderInput={(params) => (
                <TextField
                required
                {...params}
                helperText={remediationDateErr || lapsedWillRemediatePlan ? 'Remediation plan overdue' : ''}
                error={Boolean(remediationDateErr) || lapsedWillRemediatePlan}
              />
              )}
            />
          </LocalizationProvider>
        </Box>
        {vendorStatus === REMEDIATION_OPTIONS.willNotRemediate && (
          <WillNotRemediateReasonSelect
            disabled={verificationDisable}
            value={willNotRemediateReason || ''}
            onChange={handleWillNotRemediateReasonChange}
          />
        )}
        <TextField
        id={`${question.id}-remediation-comment`}
        multiline
        rows={5}
        label={'Additional Details'}
        fullWidth
        required
        onChange={handleRemediationCommentChange}
        value={remediationComment}
        sx={{ mt: 2 }}
        disabled={verificationDisable}
        inputProps={{
          sx: { overflowY: 'scroll' },
        }}
      />
      {(vendorStatus === REMEDIATION_OPTIONS.remediated || vendorStatus === REMEDIATION_OPTIONS.willNotRemediate) && (
        <>
          {question.attachmentFormLabel && (
            <Typography mb={1} mt={1} sx={{ whiteSpace: 'pre-wrap' }}>
              {question.attachmentFormLabel}
            </Typography>
          )}
          <Box mt={1}>
            <FileUploaderComp
              showCOPublicModal={true}
              boxHeight={100}
              fileList={vfiles}
              callback={onVFilesChange}
              enablePublic
              notAssessorVisible={true}
              dontShowUpload={!user || user.type !== USER_TYPES.VENDOR_ADMIN || verificationDisable}
            />
          </Box>
        </>
      )}
      <Box sx={{ mt: 2, height: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {errorMessage.length > 0 && (
          <Typography color='error'>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </>
      )}
        </>
  );
};

export default RemediationForm;
