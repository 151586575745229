// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// MUI
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccessTimeOutlined, Add, Cancel, CheckCircle, Close, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import StartNewQuestionnaireModal from '../../StartNewQuestionnaireModal';
// redux
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { QuestionnaireType, fetchPublishedQuestionnaire } from '../../../../app/store/surveySlice';
// interface
import { BaselineStatusMap, Survey } from '../../../../interfaces/survey.interface';
import { Workflow, WorkflowTypeKey } from '../../../../interfaces/workflow.interface';
import { AssessmentSurvey } from '../../../../interfaces/assessment.interface';
import User, { Badge, Company, CompanyApplication, Contact } from '../../../../interfaces/users.interface';
// service
import { WorkflowStatus as WorkflowStatusInterface, createNewWorkflow, fetchWorkflows, workflowLabels, workflowVersion, WorkflowLabels, deactivateWorkflowProcess } from '../../../../services/workflow';
import { selectBadgeFromArray, fetchGoldBadgeLabel, fetchBlueBadgeLabel, recentBadgeByType } from '../../../../services/badge';
import createSurvey, { fetchSurvey } from '../../../../services/surveys';
import { fetchAssessmentSurvey, updateAssessmentSurvey, getSiteCloudStatus } from '../../../../services/assessments';
import { fetchAll } from '../../../../app/store/questionnaireSlice';
import { canStartFiveThreeSurvey } from '../../../../services/adminUtils';
// components
import GetBadge from '../../GetBadge';
import WorkflowStatus from '../../WorkflowStatus';
import WorkflowButtons from '../../WorkflowButtons';
import AssessmentForm from '../../../Assessments/NewAssessmentModal';
import StandardDialog, { StandardDialogActions } from '../../../Modals/StandardDialog';
import { updateWorkflow } from '../../../../app/store/workflowSlice';
import { Questionnaire } from '../../../../interfaces/questionnaire.interface';
import { QuestionnaireTypes } from '../../../../services/questionnaires';
import { getQuestionnaireModalData, StartQuestionnaireModalData } from '../../../../services/surveyHelpers';
import { USER_TYPES } from '../../../../services/user';

type SiteSidebarProps = {
  isOpen: boolean;
  company: Company;
  site: Contact | null;
  isAssessment?: boolean;
  onClose: () => void;
};

const SiteSidebar = (props: SiteSidebarProps) => {
  const { isOpen, company, site, isAssessment = false, onClose } = props;

  // react & redux
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const questionnaires: Questionnaire[] = useAppSelector(state => state.questionnaires.results);
  const { site_baseline: siteBaseline } = useAppSelector((state) => state.surveys);
  const { workflows: fullWf } = useAppSelector((state) => state.workflow);

  // states
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const [workflowAnchorEl, setWorkflowAnchorEl] = useState<null | HTMLElement>(null);
  const [surveys, setSurveys] = useState<Survey[] | undefined>(undefined);
  const [assessmentSurveys, setAssessmentSurveys] = useState<undefined | AssessmentSurvey[]>(undefined);
  const [wfToDeactivate, setwfToDeactivate] = useState<undefined | Workflow>(undefined);
  const [inactiveWorkflows, setInactiveWorkflows] = useState<Workflow[]>([]);
  const canStartFiveDotThree = useMemo(() => canStartFiveThreeSurvey(user), [user]);
  const [isStartQuestionnaireModalOpen, setIsStartQuestionnaireModalOpen] = useState(false);
  const [startQuestionnaireModalData, setStartQuestionnaireModalData] = useState<StartQuestionnaireModalData | null>(null);
  const [workflowToDeactivate, setWorkflowToDeactivate] = useState<undefined | Workflow>(undefined);

  // Dynamically set the current TPN workflow type
  const CURRENT_TPN_WORKFLOW_TYPE = WorkflowLabels.slice(-1)[0] as WorkflowTypeKey; // 'tpn_best_practices_5.3'

  // local variables
  const workflows = useMemo(() => fullWf?.filter((wf) => wf.site === site?.id && wf.isActive), [fullWf, site]);
  const workflowOpen = Boolean(workflowAnchorEl);
  const wfList = useMemo(() => {
    if (workflows) return [...workflows, ...inactiveWorkflows];
    return [...inactiveWorkflows];
  }, [workflows, inactiveWorkflows]);

  // short/simple functions
  const handleWorkflowClose = useCallback(() => setWorkflowAnchorEl(null), []);

  const handleWorkflowClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setWorkflowAnchorEl(event.currentTarget);
  }, []);

  useEffect(() => {
    dispatch(fetchAll({ unpublished: false }));
  }, []);

  const handleCreateWorkflow = useCallback(
    async (type: WorkflowTypeKey) => {
      try {
        setIsCreatingWorkflow(true);
        setWorkflowAnchorEl(null);
        let finalBaseline = siteBaseline;
        if (!finalBaseline) {
          const res = await dispatch(fetchPublishedQuestionnaire(QuestionnaireTypes.SITE_BASELINE)).unwrap();
          finalBaseline = (res as QuestionnaireType).questionnaire.id;
        }

        const filteredQuestionnaires = questionnaires.filter(q => q.type === 'site_baseline');
        if (canStartFiveDotThree && !!filteredQuestionnaires.length) {
          finalBaseline = filteredQuestionnaires.sort((a, b) =>  b.id - a.id)[0].id;
        }

        const baselineSurvey = await createSurvey({
          questionnaire: finalBaseline as number,
          status: 'incomplete',
          company: company.id!,
          questionAnswers: [],
          site: site?.id!,
        });
        if (!baselineSurvey) return;

        await createNewWorkflow({
          workflowType: type,
          baseline: baselineSurvey.id,
          status: 'incomplete',
          serviceProvider: company.id!,
          site: site?.id,
        });
        navigate(`/questionnaire/${baselineSurvey.id!}/`);
      } catch (error) {
        console.log('creating workflow failed', error);
      } finally {
        setIsCreatingWorkflow(false);
      }
    },
    [dispatch, navigate, company?.id, site?.id, questionnaires, siteBaseline],
  );


  const handleStartQuestionnaire = useCallback( async (type: WorkflowTypeKey) => {
    if (!site?.id) {
      return;
    }

    if (!workflows || workflows.length === 0) {
      handleCreateWorkflow(type);
      return;
    }

    const recentSiteWorkflow = workflows
      ?.filter((wf) => wf.site === site.id)
      .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())[0];

    if (!recentSiteWorkflow) {
      return;
    }

    const modalData = getQuestionnaireModalData(
      recentSiteWorkflow.status as WorkflowStatusInterface,
      workflowVersion(recentSiteWorkflow.workflowType),
      workflowVersion(type),
      assessmentSurveys?.some((s) => s.id === recentSiteWorkflow.assessmentSurvey && (!!s.bestPracticeRemediationCount || !!s.implementationGuidanceRemediationCount)),
    );

    if (!modalData.data) {
      // Skip modal, deactivate recentWF and create new workflow
      if (workflowToDeactivate?.id) {
        await deactivateWorkflowProcess(workflowToDeactivate.id);
      }
      handleCreateWorkflow(type);
      return;
    }
    setWorkflowToDeactivate(recentSiteWorkflow);
    setStartQuestionnaireModalData(modalData);
    setIsStartQuestionnaireModalOpen(true);
  }, [workflows, site, assessmentSurveys]);

  const handleCloseStartQuestionnaireModal = useCallback(async (startWf: boolean) => {
    if (!startWf) {
      setIsStartQuestionnaireModalOpen(false);
      return;
    }

    // Deactivate the most recent workflow and start a new one
    try {
      if (workflowToDeactivate?.id) {
        await deactivateWorkflowProcess(workflowToDeactivate.id);
      }
      handleCreateWorkflow(CURRENT_TPN_WORKFLOW_TYPE);
    } catch (error) {
      console.error('Failed to deactivate workflow:', error);
    } finally {
      setIsStartQuestionnaireModalOpen(false);
      setWorkflowToDeactivate(undefined);
    }
  }, [workflowToDeactivate]);

  const companyCertification = useMemo(
    () => company?.companyCertifications?.find((cert) => cert.sites?.find((tsite) => tsite.id === site?.id)),
    [company?.companyCertifications],
  );

  const getApplicationName = useMemo(
    () => (ca: CompanyApplication) => {
      if (!company.companyApplications || company.companyApplications.length === 0) return 'Name Missing';
      const companyApplication = company.companyApplications.find((compApp) => compApp.id === ca.id);
      if (!companyApplication) return 'Name Missing';
      return companyApplication.application.name;
    },
    [company?.companyApplications],
  );

  const canAddNewWorkflow = useMemo(
    () => (type: WorkflowTypeKey) => {
      const wfs = workflows?.filter((wf) => wf.workflowType === type);
      if (wfs?.length === 0) return true;

      let badges: Badge[] = [];
      wfs?.forEach((wf) => {
        badges = badges.concat(wf.badges as Badge[]);
      });
      badges = badges
        .filter((b) => b.title === 'tpn_self_reported')
        .sort((a, b) => moment(b.expirationDate).unix() - moment(a.expirationDate).unix());

      if (badges.length === 0) return false;
      if (moment(badges[0].expirationDate).add(-3, 'months').unix() < moment().unix()) return true; // This should be removed after 5.3 is released
      return false;
    },
    [workflows],
  );

  const findAssessmentSurvey = useMemo(
    () => (workflow: Workflow) => assessmentSurveys?.find((as) => as.id === workflow.assessmentSurvey),
    [assessmentSurveys],
  );

  const findSurvey = useMemo(
    () => (workflow: Workflow) =>
      surveys?.find((sur) => (workflow.bpSurvey ? sur.id === workflow.bpSurvey : sur.id === workflow.baseline)),
    [surveys],
  );

  const mostRecentBlue = useMemo(() => recentBadgeByType(wfList, 'tpn_self_reported'), [wfList]);
  const mostRecentGold = useMemo(() => recentBadgeByType(wfList, 'tpn_assessed'), [wfList]);

  const handleFetchSurvey = useCallback(async (surveyId: number) => {
    const sur = await fetchSurvey(surveyId);
    setSurveys((cur) => {
      if (cur) return [...cur, sur];
      if (!cur) return [sur];
    });
  }, []);

  const handleFetchAssessmentSurvey = useCallback(async (assessmentSurveyId: number, status: string) => {
    const assSurvey = await fetchAssessmentSurvey(assessmentSurveyId);
    if (status !== 'Complete' || (status === 'Complete' && assSurvey.bestPracticeRemediationCount)) {
      setAssessmentSurveys((cur) => {
        if (cur) return [...cur, assSurvey];
        if (!cur) return [assSurvey];
      });
      return true;
    }
    return false;
  }, []);

  const deactivateWorkflow = async () => {
    if (!wfToDeactivate) return;
    await dispatch(updateWorkflow({ id: wfToDeactivate.id, isActive: false }));
    setwfToDeactivate(undefined);
  };

  const canDeactivate = (wf: Workflow) => user?.type === USER_TYPES.TPN_ADMIN && wf.isActive;

  const updateAssSur = (p: { assSur?: AssessmentSurvey; key: string; value: boolean }) =>
    p.assSur && updateAssessmentSurvey({ ...p.assSur, [p.key]: p.value });

  // for fetching survey or assessment survey when opening the sidebar and if it does not exists in workflows redux state
  useEffect(() => {
    const fetchData = async () => {
      if (!wfList.length) return;
      for (const workflow of wfList) {
        if (workflow?.assessmentSurvey && !assessmentSurveys?.find(sas => sas.id === workflow?.assessmentSurvey)) {
          const hasRemediationItems = await handleFetchAssessmentSurvey(workflow?.assessmentSurvey, workflow?.status);
          if (hasRemediationItems) continue;
        }
        if (!workflow?.bpSurvey) continue;
        if (workflow?.bpSurvey && !workflow?.assessment && !['submittedForAssessment', 'complete'].includes(workflow?.status.toLowerCase())) {
          if (!surveys?.find(ss => ss.id === workflow?.bpSurvey)) {
            handleFetchSurvey(workflow?.bpSurvey);
          }
        }
      }
    };
    fetchData();
  }, [wfList]);

  useEffect(() => {
    if (!site) return;
    setInactiveWorkflows([]);
    fetchWorkflows({
      site: site.id,
      service_provider: company.id,
    }, false).then(setInactiveWorkflows);
  }, [site]);

  const [siteCloudStatus, setSiteCloudStatus] = useState<BaselineStatusMap>({});

  const fetchSiteCloudStatus = async (baselineId: number) => {
    try {
      const siteCloudStatusData = await getSiteCloudStatus(baselineId);
      setSiteCloudStatus((prev) => ({
        ...prev,
        [baselineId]: siteCloudStatusData,
      }));
    } catch (error) {
      console.error('Failed to fetch site cloud status:', error);
      setSiteCloudStatus((prev) => ({
        ...prev,
        [baselineId]: null,
      }));
    }
  };

  useEffect(() => {
    if (!workflows || !isOpen) return;

    workflows.forEach(({ baseline: baselineId }) => {
      if (!baselineId || siteCloudStatus[baselineId]) return;
      fetchSiteCloudStatus(baselineId);
    });
  }, [workflows, isOpen]);

  const renderMenuItem = useCallback(
    (version: WorkflowTypeKey) => {
      return canAddNewWorkflow(version) ? (
        <MenuItem onClick={() => handleStartQuestionnaire(version)}>
          {workflowLabels(version)}
        </MenuItem>
      ) : (
        <MenuItem disabled>No new Questionnaires</MenuItem>
      );
    },
    [canAddNewWorkflow, handleStartQuestionnaire, workflowLabels],
  );

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      sx={(theme: Theme) => ({
        '& .MuiPaper-root': {
          width: 600,
          backgroundColor: '#F3F5FB',
          [theme.breakpoints.down('sm')]: {
            width: 250,
          },
        },
      })}
    >
      <Box
        component='section'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '1.25rem',
          bgcolor: '#2F2D4F',
        }}
      >
        <Typography fontSize='1.063rem' color='#fff' fontWeight='500'>
          Site Details
        </Typography>
        <IconButton sx={{ color: '#fff' }} onClick={onClose}>
          <Close sx={{ fontSize: '1.125rem' }} />
        </IconButton>
      </Box>
      <Box
        component='section'
        sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem', borderBottom: '1px solid #DBE3EF' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', color: '#575757' }}>
            <Typography>{site?.name}</Typography>
            { site?.address && (
              <Typography>
                {site.address}
                <br />{site.city}, {site.state} {site.country} {site.zipcode}
                {site?.address2 && (
                  <>
                    <br />
                    {site.address2}
                  </>
                )}
              </Typography>
            )}
            {site?.primaryContact && site?.primaryContact && (
              <Typography>
                {site.primaryContact.firstName} {site.primaryContact.lastName}
              </Typography>
            )}
            {site?.primaryContact && site?.primaryContact.contact && (
              <Typography>{site.primaryContact.contact.phoneNumber}</Typography>
            )}
            {site?.primaryContact && site?.primaryContact.email && <Typography>{site.primaryContact.email}</Typography>}
          </Box>
          <GetBadge
            isActive={company.isPublished}
            goldBadgeLabel={mostRecentGold.badge && fetchGoldBadgeLabel([mostRecentGold.badge], mostRecentGold.version)}
            blueBadgeLabel={mostRecentBlue.badge && fetchBlueBadgeLabel([mostRecentBlue.badge], mostRecentBlue.version)}
            certification={companyCertification}
            company={company.id}
          />
        </Box>
        <Typography sx={{ color: '#575757', fontWeight: 600, mt: '1rem' }}>Applications</Typography>
        <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap', mt: '0.5rem' }}>
          {site?.companyApplications?.length === 0 && <Typography>-</Typography>}
          {site?.companyApplications?.map((ca) => {
            return (
              <Chip
                key={ca.id}
                label={getApplicationName(ca)}
                size='small'
                sx={(theme) => ({
                  bgcolor: theme.palette.chips?.app,
                  color: '#FFFFFFCC',
                  '& .MuiChip-label': { px: '1rem' },
                })}
              ></Chip>
            );
          })}
        </Box>
      </Box>
      <Box
        component='section'
        sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem', borderBottom: '1px solid #DBE3EF' }}
      >
        <Typography sx={{ color: '#575757', fontWeight: 600 }}>Services</Typography>
        <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap', mt: '0.5rem' }}>
          {site?.services?.map((service) => {
            return (
              <Chip
                key={service.id}
                label={service.name}
                size='small'
                sx={(theme) => ({
                  bgcolor: theme.palette.chips?.service,
                  color: theme.palette.chips?.contrastText,
                  '& .MuiChip-label': { px: '1rem' },
                })}
              ></Chip>
            );
          })}
          {site?.services?.length === 0 && <Typography>-</Typography>}
        </Box>
      </Box>
      {!isAssessment && <Box
        component='section'
        sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem', borderBottom: '1px solid #DBE3EF' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#575757', fontWeight: 600 }}>Questionnaires</Typography>
          {user && (user.type === USER_TYPES.VENDOR_ADMIN || user.type === USER_TYPES.TPN_ADMIN) && (
            <>
              <LoadingButton
                variant='contained'
                startIcon={<Add />}
                size='small'
                loadingPosition='start'
                aria-controls={workflowOpen ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={workflowOpen ? 'true' : undefined}
                loading={isCreatingWorkflow}
                onClick={handleWorkflowClick}
              >
                New Questionnaire
              </LoadingButton>
              <Menu
                id='basic-menu'
                anchorEl={workflowAnchorEl}
                open={workflowOpen}
                onClose={handleWorkflowClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {canStartFiveDotThree ? renderMenuItem(CURRENT_TPN_WORKFLOW_TYPE) : renderMenuItem(CURRENT_TPN_WORKFLOW_TYPE)}
              </Menu>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', mt: '1rem' }}>
          {wfList?.length === 0 && <Typography>-</Typography>}
          {wfList?.map((workflow, i) => {
            return (
              <Grid container key={workflow.id} sx={{ py: 1 }}>
                {i !== 0 && <Divider />}
                <Grid item xs={2}>
                  <GetBadge
                    isActive={company.isPublished}
                    shield={selectBadgeFromArray(workflow.badges as Badge[], true)}
                    assessmentSurvey={findAssessmentSurvey(workflow)}
                    assessor={workflow?.assignedAssessor as User | undefined}
                    certification={companyCertification}
                    company={company.id}
                    goldBadgeLabel={fetchGoldBadgeLabel(workflow.badges as Badge[], workflowLabels(workflow.workflowType))}
                    blueBadgeLabel={fetchBlueBadgeLabel(workflow.badges as Badge[], workflowLabels(workflow.workflowType))}
                  />
                </Grid>
                <Grid item xs={canDeactivate(workflow) ? 5 : 6}>
                  <Typography sx={{ color: '#848484' }}>{workflowLabels(workflow.workflowType)}</Typography>
                  <Box sx={{ color: '#1876D2', fontWeight: 700 }}>
                    <WorkflowStatus
                      hasBlueExpired={[fetchBlueBadgeLabel(workflow.badges as Badge[], workflowLabels(workflow.workflowType)) || ''].toString().includes('Expired')}
                      workflow={workflow}
                      company={company}
                      survey={findSurvey(workflow)}
                      assessmentSurvey={findAssessmentSurvey(workflow)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <WorkflowButtons
                    company={company}
                    workflow={workflow}
                    survey={findSurvey(workflow)}
                    assessmentSurvey={findAssessmentSurvey(workflow)}
                  />
                </Grid>
                {canDeactivate(workflow) && (
                  <Grid item xs={1}>
                    <IconButton onClick={() => setwfToDeactivate(workflow)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                )}
                {workflow.baseline && siteCloudStatus[workflow.baseline] && (
                  <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                      <Stack spacing={0.5} justifyContent='flex-start' alignItems='flex-start' ml='-16px' mt='8px'>
                        <Typography sx={{ fontWeight: 'bold', ml: '16px' }}>Scope</Typography>
                        <FormGroup>
                          <FormControlLabel
                            labelPlacement='start'
                            disabled
                            control={
                              <Switch
                                checked={!!siteCloudStatus[workflow.baseline]?.site || !!siteCloudStatus[workflow.baseline]?.both}
                              />
                            }
                            label={workflow?.site ? 'Site' : 'On-Prem'}
                          />
                          <FormControlLabel
                            labelPlacement='start'
                            disabled
                            control={
                              <Switch
                                checked={!!siteCloudStatus[workflow.baseline]?.cloud || !!siteCloudStatus[workflow.baseline]?.both}
                              />
                            }
                            label='Cloud'
                          />
                        </FormGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={5}>
                      <Stack spacing={0.5} justifyContent='flex-start' alignItems='flex-start' ml='-16px' mt='8px'>
                        <Typography sx={{ fontWeight: 'bold', ml: '16px' }}>Type</Typography>
                        <FormGroup>
                          <FormControlLabel
                            labelPlacement='start'
                            disabled={user?.type !== USER_TYPES.TPN_ADMIN}
                            control={
                              <Switch
                                defaultChecked={findAssessmentSurvey(workflow)?.isInPerson}
                                onChange={(e, v) =>
                                  updateAssSur({ assSur: findAssessmentSurvey(workflow), key: 'isInPerson', value: v })
                                }
                              />
                            }
                            label='On Site'
                          />
                        </FormGroup>
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            );
          })}
        </Box>
      </Box>}
      <Box component='section' sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem' }}>
        <Typography sx={{ color: '#575757', fontWeight: 600, mb: '1rem' }}>Certificates</Typography>
        {!company?.companyCertifications || (company?.companyCertifications.length === 0 && <Typography>-</Typography>)}
        {company.companyCertifications &&
          company.companyCertifications.map((companyCert) => {
            return (
              <Box key={companyCert.id}>
                {companyCert.sites &&
                  companyCert.sites.map((cSite) => {
                    if (cSite.id !== site?.id) {
                      return null;
                    }

                    return (
                      <Box
                        key={cSite.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          mb: '0.5rem',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography sx={{ color: '#848484' }}>{companyCert.certification.title}</Typography>
                          <Box sx={{ display: 'flex', columnGap: '0.25rem', alignItems: 'center' }}>
                            <Typography sx={{ color: '#DF8063', fontSize: '0.9rem' }}>
                              {moment(companyCert.expirationDate).format('MM/DD/YYYY')}
                            </Typography>
                            <Tooltip title='Expiry Date'>
                              <AccessTimeOutlined sx={{ color: '#808080', fontSize: '0.9rem' }} />
                            </Tooltip>
                          </Box>
                        </Box>
                        <Tooltip title={companyCert.adminApproved ? 'Accepted' : 'Rejected'}>
                          {companyCert.adminApproved ? (
                            <CheckCircle sx={{ color: '#7DBF29' }} />
                          ) : (
                            <Cancel sx={{ color: '#DF8063' }} />
                          )}
                        </Tooltip>
                      </Box>
                    );
                  })}
              </Box>
            );
          })}
      </Box>
      <AssessmentForm
        modalOpen={assessmentModalOpen}
        company={company}
        closeCallback={() => setAssessmentModalOpen(false)}
      />
      { startQuestionnaireModalData &&
        <StartNewQuestionnaireModal
        isOpen={isStartQuestionnaireModalOpen}
        handleClose={handleCloseStartQuestionnaireModal}
        startQuestionnaireModalData={startQuestionnaireModalData}
      />
      }
      <StandardDialog
        title='Deactivate Workflow'
        isOpen={!!wfToDeactivate}
        handleClose={() => setwfToDeactivate(undefined)}
      >
        <Typography>Are you sure you wish to deactivate this workflow?</Typography>
        <StandardDialogActions>
          <Button variant='outlined' onClick={() => setwfToDeactivate(undefined)}>
            Cancel
          </Button>
          <Button variant='contained' color='error' onClick={() => deactivateWorkflow()}>
            Deactivate
          </Button>
        </StandardDialogActions>
      </StandardDialog>
    </Drawer>
  );
};

export default SiteSidebar;
