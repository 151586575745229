import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { Card, MenuItem, Link as MuiLink, Select, SelectChangeEvent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Button from '@mui/material/Button';

import getAssessmentSurveyAssessments from '../../services/assessmentSurveyAssessment';
import { AssessmentSurveyAssessment } from '../../interfaces/assessmentSurveyAssessments.interface';
import { fetchAssessment, updateAssessmentSurveySimple, updateAssessmentSingleKey } from '../../services/assessments';
import moment from 'moment';
import { getCompany, updateCompanyBoolean } from '../../services/company';
import { Survey } from '../../interfaces/survey.interface';
import { updateContactShort } from '../../services/contact';
import { updateCompanyApplicationShort } from '../../services/companyApplication';
import { Link } from 'react-router-dom';
import { Assessment, AssessmentSurvey } from '../../interfaces/assessment.interface';
import User, { Version, AssessorLevel } from '../../interfaces/users.interface';
import { getUsersByType } from '../../app/store/usersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { createBadge, updateVersionBadge } from '../../services/applications';
import { fetchWorkflows, updateWorkflow } from '../../services/workflow';
import { USER_TYPES } from '../../services/user';

let assessorUsers:User[] = [];

interface AssessmentRow {
  id: number;
  vendorName: string;
  assessorCompanyName: string;
  assessorName: string;
  assessorLevel: null | undefined | AssessorLevel | string;
  siteOrApplicationName: string;
  link: string;
  approve: string;
  fullObject: AssessmentSurveyAssessment;
  adminReviewer: number;
  submittedDate: string;
  assessmentSurvey: AssessmentSurvey;
  assessment: Assessment;
  dateAccepted: string | undefined;
  reviewStatus?: string;
}

function CustomToolbar(props: { showCompletedAssessmentSurveys: boolean, toggleShowCompletedAssessmentSurveys: () => void }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{
        fileName: `tpn_in_review_assessments_${new Date().toLocaleDateString()}`,
      }}/>
      <Button
        variant="text"
        size='small'
        onClick={props.toggleShowCompletedAssessmentSurveys}>
        Show {props.showCompletedAssessmentSurveys ? 'Submitted' : 'Completed'} Assessments
      </Button>
    </GridToolbarContainer>
  );
}

const isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

function createRowLink(assRow: AssessmentRow) {
  return (<>{assRow.link !== '-' &&
    <Link to={assRow.link}>
      <IconButton size='small' name='View Certification'>
        <LinkOutlinedIcon />
      </IconButton>
    </Link>
  }</>);
}

function createApproveButton(assRow: AssessmentRow) {
  const theRow = { ...assRow };
  const approveAssessment = async () => {
    const { assessment, assessmentSurvey } = theRow;
    const company = await getCompany(theRow.fullObject.assessments[0].company.id.toString());
    const workflow = await fetchWorkflows({ assessment_survey: assessmentSurvey.id });
    let status = assessmentSurvey.bestPracticeRemediationCount > 0 || assessmentSurvey.implementationGuidanceRemediationCount > 0 ? 'Remediation' : 'Complete';
    const newDate = moment().utc();
    updateAssessmentSurveySimple(assessmentSurvey.id!, { status, reportIssueDate: newDate.format('YYYY-MM-DDTHH:mm:ssZZ'), expirationDate: newDate.add(2, 'y').format('YYYY-MM-DDTHH:mm:ssZZ') }).then(() => {
      theRow.fullObject.status = status;
    });

    let assessmentStatus = status;

    (assessment.surveys as AssessmentSurvey[]).forEach(assSur => {
      if (assessmentSurvey.id === assSur.id || ['Remediation', 'Complete'].indexOf(assessmentStatus) === -1) return;
      if (['Remediation', 'Complete'].indexOf(assSur.status) === -1) {
        assessmentStatus = assSur.status;
        return;
      }
      if (['Remediation', 'Complete'].indexOf(assSur.status) !== -1) {
        if (assSur.status === 'Remediation' && assessmentStatus === 'Complete') {
          assessmentStatus = 'Remediation';
          return;
        }

      }
    });

    if (['Remediation', 'Complete'].indexOf(assessmentStatus) !== -1) updateAssessmentSingleKey(assessment.id!, 'status', assessmentStatus);
    if (assessmentStatus === 'Remediation') return updateWorkflow({ id: workflow[0].id, reportIssueDate: newDate.format('YYYY-MM-DD'), status: assessmentStatus });
    newDate.add(-2, 'y');
    if (theRow.fullObject.assessments?.length > 0 === false) return updateWorkflow({ id: workflow[0].id, reportIssueDate: newDate.format('YYYY-MM-DD'), status: assessmentStatus });
    updateCompanyBoolean({ id: company.id, displayShieldType: 'tpn_assessed', goldShieldDate: newDate.format('YYYY-MM-DD') });
    if ((assessmentSurvey.survey as Survey).site) {
      updateContactShort({ id: (assessmentSurvey.survey as Survey).site, badge: 'tpn_assessed', goldShieldDate: newDate.format('YYYY-MM-DD') });
      if (workflow?.length) {
        createBadge({
          title: 'tpn_assessed',
          awardedDate: newDate.format('YYYY-MM-DD'),
          expirationDate: moment(newDate.format('YYYY-MM-DD')).add(2, 'years').format('YYYY-MM-DD'),
        }).then(newBadge => {
          const badges = workflow[0]?.badges ? workflow[0]?.badges?.map(b => typeof b === 'number' ? b : b.id!) : [];
          updateWorkflow({ id: workflow[0].id, badges: [...badges, newBadge.id!], reportIssueDate: newDate.format('YYYY-MM-DD'), status: assessmentStatus });
        });
      }
    }
    if ((assessmentSurvey.survey as Survey).application) {
      const companyApplication = company.companyApplications?.find(ca => ca.application.id === (assessmentSurvey.survey as Survey).application);
      if (!companyApplication) return;

      const version = (companyApplication.application.versions as Version[]).find(v => v.value === (assessmentSurvey.survey as Survey).version);
      const date = moment().format('YYYY-MM-DD');
      if (version) {
        createBadge({
          title: 'tpn_assessed',
          awardedDate: date,
          expirationDate: moment(date).add(2, 'years').format('YYYY-MM-DD'),
        }).then(newBadge => {
          updateVersionBadge(version.id!, newBadge.id!);
          if (workflow?.length) {
            const badges = workflow[0]?.badges ? workflow[0]?.badges?.map(b => typeof b === 'number' ? b : b.id!) : [];
            updateWorkflow({ id: workflow[0].id, badges: [...badges, newBadge.id!], status: assessmentStatus });
          }
        });
      }

      if (companyApplication.badge !== 'tpn_assessed') updateCompanyApplicationShort({ id: companyApplication.id, badge: 'tpn_assessed', goldShieldDate: date });
    }
  };

  return (<>
    {['Remediation', 'Complete', 'Completed'].indexOf(theRow.fullObject.status) === -1 && <Button variant="outlined" color="primary" onClick={() => approveAssessment()}>{theRow.approve}</Button> }
    {['Remediation', 'Complete', 'Completed'].indexOf(theRow.fullObject.status) > -1 && 'Approved'}
  </>);
}

function selectAdmin(assRow: AssessmentRow) {
  const handleSetReviewer = (event: SelectChangeEvent) => {
    if (!event || !event.target.value) return;
    const user = event.target.value;
    updateAssessmentSurveySimple(assRow.id, { adminReviewer: user });
  };

  return (<>
    { !assessorUsers && assRow.adminReviewer }
    { assessorUsers &&
      <Select
        labelId="company-primary-contact"
        id="company-primary-contact-select"
        label="Primary Contact"
        onChange={handleSetReviewer}
        defaultValue={assRow.adminReviewer?.toString()}
        fullWidth
      >
        {assessorUsers.filter(e => e.firstName?.length > 0 || e.lastName?.length > 0).map((admin: User) =>
          <MenuItem key={admin.id} value={admin.id} selected={true}>{admin.firstName} {admin.lastName}</MenuItem>,
        )}
      </Select>
    }
  </>);
}

function reviewStatus(assRow: AssessmentRow) {
  const handleSetReviewer = (event: SelectChangeEvent) => {
    if (!event || !event.target.value) return;
    const status = event.target.value;
    updateAssessmentSurveySimple(assRow.id, { reviewStatus: status });
  };

  return (<>
    <Select
      labelId="review-status"
      id="review-status-select"
      label="Reivew Status"
      onChange={handleSetReviewer}
      defaultValue={assRow.reviewStatus}
      fullWidth
    >
      <MenuItem value='reviewing'>TPN Reviewing</MenuItem>
      <MenuItem value='pending_assessor'>Pending Assessor</MenuItem>
      <MenuItem value='updates_complete'>Updates Complete</MenuItem>
    </Select>
  </>);
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'vendorName', headerName: 'Vendor', width: 300 },
  { field: 'siteOrApplicationName', headerName: 'Site or Application Name', width: 300 },
  { field: 'assessorCompanyName', headerName: 'Assessor Company', width: 300 },
  { field: 'assessorName', headerName: 'Assessor', width: 300, renderCell: (params) => !isEmail.test(params.row.assessorName) ? params.row.assessorName : <MuiLink href={`mailto:${params.row.assessorName}`}>{params.row.assessorName}</MuiLink> },
  { field: 'assessorLevel', headerName: 'Assessor Level', width: 250 },
  { field: 'dateAccepted', headerName: 'Date Accepted', width: 150 },
  { field: 'submittedDate', headerName: 'Date Submitted', width: 150 },
  { field: 'adminReviewer', headerName: 'Assigned Reviewer', width: 300, renderCell: params => selectAdmin(params.row), valueFormatter: ({ value }) => {
    if (!value) return;
    const admin = assessorUsers.find(u => u.id === value);
    if (!admin) return;
    return `${admin.firstName} ${admin.lastName}`;
  } },
  { field: 'link', headerName: 'Assessment', width: 150, renderCell: params => createRowLink(params.row) },
  { field: 'approve', headerName: 'Action', width: 200, renderCell: params => createApproveButton(params.row), disableExport: true },
  { field: 'reviewStatus', headerName: 'Review Status', width: 300, renderCell: params => reviewStatus(params.row), valueFormatter: ({ value }) => {
    if (value === 'reviewing') return 'TPN Reviewing';
    if (value === 'pending_assessor') return 'Pending Assessor';
    if (value === 'updates_complete') return 'Updates Complete';
    return;
  } },
];

function createAssessmentRow(assSurAss: AssessmentSurveyAssessment, assessmentSurvey: AssessmentSurvey, assessment: Assessment) {
  const approve = 'Approve';
  if (assSurAss.assessments.length === 0) return {
    id: assSurAss.id,
    vendorName: '',
    assessorCompanyName: '',
    assessorName: '',
    assessorLevel: null,
    siteOrApplicationName: '',
    link: '-',
    approve,
    fullObject: assSurAss,
    adminReviewer: assSurAss.adminReviewer as number,
    submittedDate: assSurAss.submittedDate as string,
    dateAccepted: assessment.dateAccepted,
    assessmentSurvey,
    assessment,
  };
  const link = `/assessment/${assSurAss.assessments[0].id}/questionnaire/${assSurAss.survey}`;

  const survey = assSurAss.survey;
  const vendor = assSurAss.assessments[0].company;
  const assessorCompany = assSurAss.assessments[0].assessor;
  const assessorName = assSurAss.assessments[0].assignedAssessor?.firstName ? assSurAss.assessments[0].assignedAssessor.firstName + ' ' + assSurAss.assessments[0].assignedAssessor.lastName : assSurAss.assessments[0].assignedAssessor?.email;
  let siteApp = '';

  if (!assSurAss.submittedDate) {
    updateAssessmentSurveySimple(assSurAss.id, { submittedDate: assSurAss.updatedAt });
    assSurAss.submittedDate = assSurAss.updatedAt;
  }

  if (vendor.companyApplications && vendor.companyApplications.length > 0) {
    vendor.companyApplications.forEach(CA => {
      if (siteApp.length === 0 && CA.application.surveys.indexOf(survey) !== -1) siteApp = CA.application.name;
    });
  }

  if (siteApp.length === 0 && vendor.locations && vendor.locations.length > 0) {
    vendor.locations.forEach(loc => {
      if (siteApp.length === 0 && loc.surveys.indexOf(survey) !== -1) siteApp = loc.name;
    });
  }

  return {
    id: assSurAss.id,
    vendorName: vendor.name,
    assessorCompanyName: assessorCompany.name,
    assessorName,
    assessorLevel: assessorCompany.assessorLevel ? assessorCompany.assessorLevel.descriptor : 'Null',
    siteOrApplicationName: siteApp,
    link,
    approve,
    fullObject: assSurAss,
    adminReviewer: assSurAss.adminReviewer as number,
    submittedDate: assSurAss.submittedDate ? moment(assSurAss.submittedDate).format('MM/DD/YYYY') : '-',
    dateAccepted: assessment.dateAccepted ? moment(assessment.dateAccepted).format('MM/DD/YYYY') : '-',
    assessmentSurvey,
    assessment,
    reviewStatus: assessmentSurvey.reviewStatus,
  };
}

export default function AssessmentAdminPanel(props: { assessmentsAddressed: any }) {
  const dispatch = useAppDispatch();
  assessorUsers = useAppSelector(state => state.users).results || [];

  const [rows, setRows] = useState<Array<AssessmentRow>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAssSur = async (assessmentSurveyAssessments: AssessmentSurveyAssessment[]) => {
    if (!assessmentSurveyAssessments.length) return;
    const assessments = await Promise.all(assessmentSurveyAssessments.filter(asa => asa.assessments.length > 0).map(asa => fetchAssessment(asa.assessments[0].id)));
    if (!assessments) return;
    setIsLoading(false);
    setRows(
      assessmentSurveyAssessments
        .filter(assSurAss => assSurAss.assessments.length > 0)
        .sort((a, b) => a.assessments.length && b.assessments.length && a.assessments[0].company && b.assessments[0].company ? a.assessments[0].company.name.localeCompare(b.assessments[0].company.name) : -1).map((assSurAss: AssessmentSurveyAssessment) => {
          const assessment = assessments.find(as => as.id === assSurAss.assessments[0].id);
          const assessmentSurvey = (assessment!.surveys as AssessmentSurvey[]).find(as => as.id === assSurAss.id);
          return createAssessmentRow(assSurAss, assessmentSurvey!, assessment!);
        }));
    props.assessmentsAddressed();
  };

  const [showCompletedAssessmentSurveys, setShowCompletedAssessmentSurveys] = useState(false);
  useEffect(() => {
    dispatch(getUsersByType(USER_TYPES.TPN_ADMIN));
    if (rows) setRows([]);
    setIsLoading(true);
    getAssessmentSurveyAssessments( { statuses: showCompletedAssessmentSurveys ? ['Complete', 'Remediation'] : ['Submitted'] }).then((assessmentSurveyAssessments: AssessmentSurveyAssessment[]) => getAssSur(assessmentSurveyAssessments));
  }, [showCompletedAssessmentSurveys]);

  const toggleShowCompletedAssessmentSurveys = () => setShowCompletedAssessmentSurveys(!showCompletedAssessmentSurveys);

  return (
    <Card sx={{ padding: '2em' }}>
      <DataGrid
        style={{ height: 400, width: '100%' }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={isLoading}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: () => (
            <GridOverlay>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <CircularProgress />
              </div>
            </GridOverlay>
          ),
        }}
        componentsProps={{
          toolbar: { showCompletedAssessmentSurveys: showCompletedAssessmentSurveys, toggleShowCompletedAssessmentSurveys: toggleShowCompletedAssessmentSurveys },
        }}
      />
    </Card>
  );
}
