import React, { useState, useEffect, useCallback } from 'react';
import { SiteCloudStatus, Survey } from '../../../interfaces/survey.interface';
import { Company } from '../../../interfaces/users.interface';
import { Stack, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { DataCell, DataRow } from '../../Tables/DataList';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MultipleSelectChip from './MultiSelect';
import { Assessment, AssessmentSurvey } from '../../../interfaces/assessment.interface';
import { getSiteCloudStatus } from '../../../services/assessments';
import { fetchWorkflows } from '../../../services/workflow';
import { USER_TYPES } from '../../../services/user';

const getSurveyOptionLabel = (props: { curSurvey:Survey | number, company:Company, surveys?: Survey[] }) => {
  let curSurvey: Survey | undefined = typeof props.curSurvey === 'number' ? undefined : props.curSurvey;
  if (typeof props.curSurvey === 'number') curSurvey = props.surveys?.find(survey => survey.id === props.curSurvey);
  if (!curSurvey) return 'Survey Type Missing';
  if (curSurvey.site) {
    const site = props.company.locations.find(loc => loc.id === curSurvey?.site);
    if (site) return site.name;
    return 'Site Missing';
  }
  if (curSurvey.application) {
    const app = props.company.companyApplications?.find(napp => napp.application.id === curSurvey?.application);
    if (app) return app.application.name + ' ' + curSurvey.version;
    return 'App Missing';
  }
  return 'Survey Type Missing';
};

function SurveyRow(props: ({ workflowBaseline?: number, survey: Survey, company: Company, removeSurveyCallback: (survey:Survey) => void, assessmentSurvey: AssessmentSurvey, udpateAssessmentSurvey: (assessmentSurvey:AssessmentSurvey) => void, handleShowInPersonModal: () => void })) {
  const { user } = useAppSelector(state => state.user);
  const { survey, company, removeSurveyCallback, assessmentSurvey, udpateAssessmentSurvey } = props;

  const [isOnPremise, setIsOnPremise] = useState(survey.site ? true : assessmentSurvey.isOnPremise);
  const [isCloud, setisCloud] = useState(assessmentSurvey.isCloud);
  const [isInPerson, setisInPerson] = useState(assessmentSurvey.isInPerson);
  const [siteCloudStatus, setSiteCloudStatus] = useState<SiteCloudStatus>({ site: false, cloud: false, both: false });

  useEffect(() => {
    udpateAssessmentSurvey({ ...props.assessmentSurvey, isOnPremise, isCloud, isInPerson });
  }, [isOnPremise, isCloud, isInPerson]);

  const fetchSiteCloudStatus = async (baselineId: number) => {
    const siteCloundData = await getSiteCloudStatus(baselineId);
    setSiteCloudStatus(siteCloundData);
  };

  useEffect(() => {
    if (props.workflowBaseline) fetchSiteCloudStatus(props.workflowBaseline);
  }, [props.workflowBaseline]);

  useEffect(() => {
    setIsOnPremise(assessmentSurvey.isOnPremise);
    setisCloud(assessmentSurvey.isCloud);
    setisInPerson(assessmentSurvey.isInPerson);
  }, [assessmentSurvey]);

  const toggIsInPerson = useCallback(() => {
    if (isInPerson) {
      props.handleShowInPersonModal();
    }
    setisInPerson(!isInPerson);
  }, [isInPerson]);
  
  return (
    <DataRow>
      <DataCell xs={4}>{getSurveyOptionLabel({ curSurvey: survey, company })}</DataCell>
      <DataCell xs={4}>
        <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <FormGroup>
            <FormControlLabel labelPlacement='start' disabled control={<Switch checked={ siteCloudStatus.site || siteCloudStatus.both } />} label={survey.site ? 'Site' : 'On-Prem'} />
            <FormControlLabel labelPlacement='start' disabled control={<Switch checked={ siteCloudStatus.cloud || siteCloudStatus.both } />} label="Cloud" />
          </FormGroup>
        </Stack>
      </DataCell>
      <DataCell xs={3}>
        <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <FormGroup>
            <FormControlLabel labelPlacement='start' disabled={user?.type === USER_TYPES.ASSESSOR} control={<Switch checked={isInPerson} onChange={toggIsInPerson} />} label="On Site" />
          </FormGroup>
        </Stack>
      </DataCell>
      <DataCell xs={1}>
        <IconButton size='small' name='Remove From Assessment' onClick={() => removeSurveyCallback(survey)}>
          <DeleteIcon />
        </IconButton>
      </DataCell>
    </DataRow>
  );
}

export default function SurveySelectList(props: { surveys: Survey[], selectedSurveys: Survey[], company: Company, assessment?: Assessment, surveysCallBack: (surveys:Survey[], assessmentSurvey:AssessmentSurvey[]) => void, handleShowInPersonModal: () => void }) {
  const { user } = useAppSelector(state => state.user);
  const [selectedSurveys, setSelectedSurveys] = useState(props.selectedSurveys);
  const [selectedSurveyIds, setSelectedSurveyIds] = useState(props.selectedSurveys?.map(ss => ss.id));
  const [surveys, setSurveys] = useState(props.surveys);
  const [assessmentSurveys, setAssessmentSurveys] = useState(props.assessment ? (props.assessment.surveys as AssessmentSurvey[]).map(assessmentSurvey => { return { ...assessmentSurvey, survey: typeof assessmentSurvey.survey === 'number' ? assessmentSurvey.survey : assessmentSurvey.survey.id }; }) : []);
  const [workflowBaseline, setWorkflowBaseline] = useState<number | undefined>(undefined);

  useEffect(() => {
    setSurveys(props.surveys);
  }, [props.surveys]);

  useEffect(() => {
    props.surveysCallBack(selectedSurveys, assessmentSurveys);
  }, [selectedSurveys, assessmentSurveys]);

  useEffect(() => {
    setSelectedSurveys(props.selectedSurveys);
    setSelectedSurveyIds(props.selectedSurveys.map(ss => ss.id));
    setAssessmentSurveys(props.assessment ? (props.assessment.surveys as AssessmentSurvey[]).map(assessmentSurvey => { return { ...assessmentSurvey, survey: typeof assessmentSurvey.survey === 'number' ? assessmentSurvey.survey : assessmentSurvey.survey.id }; }) : []);
  }, [props.selectedSurveys, props.assessment]);

  const removeSurvey = (survey:Survey) => {
    const newSelectedSurveys = selectedSurveys.filter(sur => sur.id !== survey.id);
    setSelectedSurveys(newSelectedSurveys);
    setSelectedSurveyIds(newSelectedSurveys.map(ss => ss.id));
  };

  const handleSurveyChange = async (event: number[]) => {
    if (!event) return;
    const wfs = await fetchWorkflows({ bp_survey: event[0] }); // fetch workflow baseline on survey selection
    if (wfs.length > 0) setWorkflowBaseline(wfs[0].baseline);
    const nsurveys = props.surveys.filter(survey => event.indexOf(survey.id) !== -1);
    const newAssessmentSurveys:AssessmentSurvey[] = assessmentSurveys ? [ ...assessmentSurveys ] : [];
    nsurveys.forEach(survey => {
      const assessmentSurvey = assessmentSurveys?.findIndex(assSur => assSur.survey === survey.id);
      if (assessmentSurvey !== undefined && assessmentSurvey !== -1) return;
      newAssessmentSurveys.push({
        survey: survey.id,
        isOnPremise: survey.site ? true : false,
        isCloud: false,
        isInPerson: true,
        bestPracticeRemediationCount: 0,
        implementationGuidanceRemediationCount: 0,
        assessorAnswers: [],
        status: 'Assigned',
      });
    });
    setAssessmentSurveys(newAssessmentSurveys);
    setSelectedSurveyIds(event);
    setSelectedSurveys(nsurveys);
  };

  const udpateAssessmentSurvey = (assessmentSurvey:AssessmentSurvey) => {
    const nAssessmentSurveys = [ ...assessmentSurveys ];
    const assessmentSurveyIndex = nAssessmentSurveys.findIndex(nass => nass.survey === assessmentSurvey.survey);
    if (assessmentSurveyIndex === -1) return;
    nAssessmentSurveys[assessmentSurveyIndex] = assessmentSurvey;
    setAssessmentSurveys(nAssessmentSurveys);
  };

  return (<>
    <MultipleSelectChip selectionsLimit={1} options={surveys} disabled={user?.type === USER_TYPES.ASSESSOR} getOption={(survey:Survey | number) => getSurveyOptionLabel({ curSurvey: survey, company: props.company, surveys: props.surveys })} optionsCallback={handleSurveyChange} selectedOptions={selectedSurveyIds} inputLabel='Search Sites and Applications, Select One' />
    {selectedSurveys.length > 0 && user?.type !== USER_TYPES.ASSESSOR && <>
      <DataRow header>
        <DataCell xs={4}>Site/Application</DataCell>
        <DataCell xs={4}>Scope</DataCell>
        <DataCell xs={4}>Type</DataCell>
      </DataRow>
      {selectedSurveys.map((survey) => <SurveyRow workflowBaseline={workflowBaseline} survey={survey} company={props.company} key={survey.id} handleShowInPersonModal={props.handleShowInPersonModal} removeSurveyCallback={removeSurvey} udpateAssessmentSurvey={udpateAssessmentSurvey} assessmentSurvey={assessmentSurveys.find(assSur => assSur.survey === survey.id)!} />)}
    </>}
  </>);
}