import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Typography, FormControl, TextField, CircularProgress,
  Autocomplete, IconButton, InputAdornment, Button,
} from '@mui/material';
import { CheckCircle, Cancel, Close, AddCircle } from '@mui/icons-material';
import { Company } from '../../../interfaces/users.interface';
import { getCompanies } from '../../../services/company';
import debounce from 'lodash/debounce';
import { getEngagementUserByEmail } from '../../../services/engagements';
import { Engagement, Grantee, RemovedUser } from '../../../interfaces/engagements';

const ShareWith = ({
  userCompany,
  inheritedUsers,
  inheritedReport,
  selectedEmployees,
  setSelectedEmployees,
  companyOptions,
  setCompanyOptions,
  setEmployeeList,
  company,
  setCompany,
}: {
  userCompany?: Company;
  inheritedUsers?: Grantee[];
  inheritedReport?: Engagement | null;
  selectedEmployees: Grantee[];
  setSelectedEmployees: (employees: Grantee[]) => void;
  company: Company | null;
  setEmployeeList: (employees: Grantee[]) => void;
  companyOptions: Company[];
  setCompanyOptions: (companies: Company[]) => void;
  setCompany: (company: Company | null) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [companySearch, setCompanySearch] = useState('');
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [emailValidation, setEmailValidation] = useState<{ valid: boolean | null, employee?: Grantee }>({ valid: null, employee: undefined });

  const debouncedHandleEmployeeSearch = useCallback(
    debounce(async (email: string, companyId?: number) => {
      const trimmedEmail = email.trim();
      if (!trimmedEmail || !companyId) {
        setEmailValidation({ valid: null });
        return;
      }

      try {
        setLoading(true);
        const employee = await getEngagementUserByEmail({ granteeCompanyId: companyId, email: trimmedEmail });
        if (employee) {
          setEmailValidation({ valid: true, employee });
        } else {
          setEmailValidation({ valid: false });
        }
      } catch (error) {
        setEmailValidation({ valid: false });
      } finally {
        setLoading(false);
      }
    }, 500),
    [company],
  ); 
 
  useEffect(() => {
    debouncedHandleEmployeeSearch(employeeSearch, company?.id);
    return () => debouncedHandleEmployeeSearch.cancel();
  }, [employeeSearch, debouncedHandleEmployeeSearch, company]);

  const handleSetInheritedEmployees = useCallback((employeesList: Grantee[]) => {
    if (inheritedReport) {
      setSelectedEmployees([ ...employeesList]);

    }
  }, [inheritedReport?.grantees, selectedEmployees, setSelectedEmployees, inheritedUsers]);

  const handleAddEmployee = useCallback(() => {
    if (emailValidation.valid && emailValidation.employee) {
      if (!selectedEmployees.some((emp) => emp.email === emailValidation.employee?.email)) {
        setSelectedEmployees([...selectedEmployees, emailValidation.employee]);
      }
      setEmployeeSearch('');
      setEmailValidation({ valid: null });
    }
  }, [emailValidation, selectedEmployees, setSelectedEmployees]);

  const handleRemoveEmployee = (email: string) => {
    setSelectedEmployees(selectedEmployees.filter(emp => emp.email !== email));
  };

  // Handle company selection & reset employees if company is cleared
  const handleSetSearchCompany = useCallback(async (searchCompany: Company | null, autoPopulate? :boolean) => {
    if (!searchCompany) {
      setCompany(null);
      setEmployeeList([]);
      setSelectedEmployees([]);
      setCompanySearch('');
      return;
    }    
    setCompany(searchCompany);

    if (!searchCompany.id) return;

    if (autoPopulate && !!inheritedUsers) {
      handleSetInheritedEmployees(inheritedUsers);
      setEmployeeList(inheritedUsers);
    }
  
    if (!autoPopulate) setSelectedEmployees([]);
  }, [setCompany, setEmployeeList, setSelectedEmployees, inheritedUsers]);

  // Fetch companies dynamically
  const fetchCompanies = useCallback(async (searchTerm: string, autoSelect?: boolean) => {
    if (!searchTerm) {
      setCompanyOptions([]);
      return;
    }

    setLoading(true);
    try {
      const results = await getCompanies(`?limit=10&type=vendor&ordering=-created_at&is_published=true&search=${searchTerm}`);
      setCompanyOptions(results.filter((c) => c.id !== userCompany?.id));
      if (autoSelect && results.length > 0) {
        handleSetSearchCompany(results[0], true);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  }, [setCompanyOptions, setCompany, userCompany]);

  // Debounced company search
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchCompanies(companySearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [companySearch, fetchCompanies]);

  useEffect(() => {
    if (!company) {
      setEmployeeList([]);
      setSelectedEmployees([]);
      setCompanySearch('');
    }
  }, [company]);

  useEffect(() => {
    if (inheritedReport) {
      const granteeCompany = (inheritedReport.grantees?.[0] as Grantee)?.companyName;
      const removedUserCompany = (inheritedReport.removedUsers?.[0] as unknown as RemovedUser)?.engagementUser?.companyName;

      fetchCompanies(granteeCompany || removedUserCompany, true);
    }
  }, [inheritedReport]);

  return (
      <>
        <Typography variant="h6" sx={{ color: '#333', mb: 1 }}>
          Who Would You Like to Share This Report With?
        </Typography>
        {/* Company Selection */}
        <FormControl fullWidth>
          <Autocomplete
            options={companyOptions}
            getOptionLabel={(option) => option.name}
            loading={loading}
            value={companyOptions.find((c) => c.id === company?.id) || null}
            loadingText={<CircularProgress size={24} />}
            onChange={(event, newValue) => handleSetSearchCompany(newValue)}
            inputValue={companySearch}
            onInputChange={(event, newInputValue) => setCompanySearch(newInputValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Company"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {company?.logo && (
                        <Box
                          component="img"
                          src={company.logo}
                          alt={company.name}
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            mx: 1,
                          }}
                        />
                      )}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ display: 'flex', alignItems: 'center', gap: 1.5, p: 1 }}
                {...props}
              >
                <Box
                  sx={{ width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  {option.logo ? (
                    <img
                      src={option.logo}
                      alt={option.name}
                      style={{ width: 24, height: 24, borderRadius: '50%' }}
                    />
                  ) : (
                    <Box sx={{ width: 24, height: 24, borderRadius: '50%', backgroundColor: 'transparent' }} />
                  )}
                </Box>
                <Typography variant="body1">{option.name}</Typography>
              </Box>
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Enter Employee Email"
              variant="outlined"
              value={employeeSearch}
              onChange={(e) => setEmployeeSearch(e.target.value)}
              sx={{ flex: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loading ? <CircularProgress size={20} /> : null}
                    {emailValidation.valid === true && <CheckCircle color="success" />}
                    {emailValidation.valid === false && <Cancel color="error" />}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
          {emailValidation.valid === false ? (
            <Typography 
              color="error" 
              sx={{ ml: 1, mt: 1, visibility: emailValidation.valid === false ? 'visible' : 'hidden' }}
            >
              No matching email found for this company.
            </Typography>
          ) : emailValidation.valid === true ? (
            <Button
              onClick={handleAddEmployee}
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
              sx={{
                mt: 1,
                px: 1.5,
                py: 0.5,
                fontSize: '0.875rem',
                fontWeight: 500,
                borderRadius: '6px',
                textTransform: 'none',
                boxShadow: 'none',
                minWidth: 'auto',
                alignSelf: 'flex-start',
              }}
            >
              Add User
            </Button>
          ) : null}
        </Box>
      </FormControl>
      {/* Selected Employees List */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, flexWrap: 'wrap', mb: 2 }}>
        {selectedEmployees.map((employee) => (
        <Box
          key={employee.email}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            px: 2,
            py: 0.8,
            borderRadius: '6px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f7f9f9',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08)',
          }}
        >
        <Typography variant="body1" sx={{ fontWeight: 400, color: '#333' }}>
          {`${employee.email} (${employee.firstName || 'Unknown'} ${employee.lastName || ''})`}
        </Typography>
        <IconButton onClick={() => handleRemoveEmployee(employee.email)}>
            <Close />
        </IconButton>
      </Box>
        ))}
    </Box>
  </>
  );
};
  
export default ShareWith;  