import React from 'react';
import { Questionnaire } from '../interfaces/questionnaire.interface';
import { BestPracticeStatus, Survey } from '../interfaces/survey.interface';
import User, { CompanyCertification } from '../interfaces/users.interface';
import { statuses } from './questionnaireQuestionStatus';
import { AssessmentQuestionStatusById, BPStatusById, PaletteKey } from '../app/store/questionnaireAnswerSlice';
import { appTheme } from '../features/Themes/light';
import { Workflow } from '../interfaces/workflow.interface';
import { AssessmentSurvey, AssessorAnswer, PIDetails } from '../interfaces/assessment.interface';
import BPRemediationCircles from '../features/Assessments/BPRemediationCircles';
import { Typography, LinearProgress, Box, Theme } from '@mui/material';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import ChatIcon from '@mui/icons-material/Chat';
import ViewIcon from '@mui/icons-material/Visibility';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { WORKFLOW_STATUSES, WorkflowStatus } from './workflow';
import { Question } from '../interfaces/question.interface';
import { QuestionAnswer } from '../interfaces/questionAnswer.interface';
import { BestPractice } from '../interfaces/bestPractice.interface';
import { Answer } from '../interfaces/answer.interface';
import { USER_TYPES } from './user';

export const isWorkflowInAssessmentRange = (status?: WorkflowStatus): boolean => {
  if (!status) return false;

  const specificStatuses: WorkflowStatus[] = [
    WORKFLOW_STATUSES.ASSIGNED,
    WORKFLOW_STATUSES.PRE_ASSESSMENT,
    WORKFLOW_STATUSES.ASSESSING,
    WORKFLOW_STATUSES.SUBMITTED,
    WORKFLOW_STATUSES.REJECTED,
    WORKFLOW_STATUSES.REMEDIATION,
    WORKFLOW_STATUSES.FINAL_COMPLETE,
  ];

  return specificStatuses.includes(status);
};

export const assessmentSteps = [
  'Site/App Baseline In Progress',
  'Site/App Baseline Complete',
  'Best Practices Questionnaire In Progress',
  'Self-Reporting Complete',
  'Assessment Assigned',
  'Pre-Assessment In Progress',
  'Assessment In Progress',
  'Assessment Submitted for Review',
  'Assessment Completed Pending Remediation Plan',
  'Assessment Complete Remediation Plan Complete',
  'Assessment Complete',
];

export const REMEDIATION_VERIFICATION_STATUS = {
  reviewed: 'reviewed',
  rejected: 'rejected',
  notStarted: 'not_started',
};

export const REMEDIATION_VERIFICATION_STATUS_LABELS = {
  reviewed: 'Reviewed',
  rejected: 'Rejected',
  not_started: 'Not Started',
};

export const REMEDIATION_PLAN_OVERDUE = 'remediation_plan_overdue';
export const REMEDIATION_VALIDATION_STATUS_UPDATE = 'remediation_validation_status_update';
export const ADMIN_REMEDIATION_VALIDATION  = 'admin_remediation_validation';

export const NOTIFICATION_TYPES = {
  [REMEDIATION_PLAN_OVERDUE]: 'Remediation Plan Past-Due',
  [REMEDIATION_VALIDATION_STATUS_UPDATE]: 'Remediation Validation Status Update',
  [ADMIN_REMEDIATION_VALIDATION ]: 'Admin: Remediation Plan Requires Validation',
} as const;

export const PARTIALLY_IMPLEMENTED = 'Partially Implemented';
export const FULLY_IMPLEMENTED = 'Fully Implemented';
export const NOT_IMPLEMENTED = 'Not Implemented';

export const NOT_APPLICABLE = 'Not Applicable';

export type NotificationTypeKey = keyof typeof NOTIFICATION_TYPES;

export const REMEDIATION_OPTIONS = {
  remediated: 'remediated',
  willNotRemediate: 'will_not_remediate',
  willRemediateLater: 'will_remediate_later',
};

export const REMEDIATION_OPTIONS_LABELS = {
  remediated: 'Remediated',
  will_not_remediate: 'Will not Remediate',
  will_remediate_later: 'Will Remediate Later',
};

export const WILL_NOT_REMEDIATE_REASON_LABELS = {
  compensating_control: 'Compensating control',
  other: 'Other',
};


export const SURVEY_ACTIONS_LABELS = {
  pending: 'Pending',
  selfAttested: 'Begin Survey',
  reviewAndSubmit: 'Review and Submit',
  scheduleAssessment: 'Schedule Assessment',
  updateAssessment: 'Update Assessment',
  viewRemediations: 'View Remediations',
  viewQuestionnaire: 'View Questionnaire',
  continueQuestionnaire: 'Continue Questionnaire',
};

const filterCompanyCert = (props: { site?: number, application?: number, companyCerts?: CompanyCertification[] }) => {
  if (!props.companyCerts || (!props.site && !props.application)) return false;
  if (props.site) return props.companyCerts.some(ccert => ccert.sites.some(s => s.id === props.site));
  if (props.application) return props.companyCerts.some(ccert => ccert.applications.some(s => s.id === props.application));
  return false;
};

export const getSurveyQuestionsAndAnswersCount = (bpStatuses?: BestPracticeStatus[]) => {
  if (!bpStatuses || bpStatuses.length === 0) {
    return { questionsCount: 0, answersCount: 0 };
  }

  return bpStatuses.reduce((acc, obj) => ({
    questionsCount: acc.questionsCount + ((obj?.status?.toLowerCase() !== 'hide' ? (obj?.visibleQuestionsCount || 0) : 0)),
    answersCount: acc.answersCount + (obj?.questionsAnsweredCount || 0),
  }), { questionsCount: 0, answersCount: 0 });
};

export const surveyDisplayLogic = (props: { survey?: Survey, companyCerts?: CompanyCertification[], workflow?: Workflow }) => {
  const {
    survey,
    companyCerts,
    workflow,
  } = props;
  if (!workflow) return '-';
  if (workflow?.status === 'submittedForAssessment') return 'Self-Reporting Complete';
  if (workflow?.status?.toLowerCase() === 'complete') return 'Complete';
  if (!survey) return 'Pending';
  const hasCompanyCert = workflow?.bpSurvey ? filterCompanyCert({ site: workflow.site, application: workflow.applicationVersion as number, companyCerts }) : false;
  if (!survey.bestPracticeStatuses || survey.bestPracticeStatuses.length === 0) return hasCompanyCert ? 'Self-Attested' : 'Pending';
  const { questionsCount, answersCount } = getSurveyQuestionsAndAnswersCount(survey?.bestPracticeStatuses as BestPracticeStatus[]);

  if (questionsCount === 0 || answersCount === 0) return hasCompanyCert ? 'Self-Attested' : 'Pending';
  if (questionsCount !== answersCount) return  (
    <>
    <Typography>{(survey.questionnaire as Questionnaire).title}</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1, paddingTop: '5px' }}>
  <LinearProgress
    variant="determinate"
    value={(answersCount / questionsCount) * 100}
    sx={{
      height: '8px',
      borderRadius: '1rem',
      width: '100%',
      color: 'primary',
    }}
  />
  <Typography sx={{ minWidth: 'fit-content', fontSize: '11px' }}>{Math.round((answersCount / questionsCount) * 100)}%</Typography>
</Box>
  </>
  );
  return survey.status;
};

export const assessmentDisplayLogic = (status:string) => {
  switch (status) {
    case 'Assigned':
    // Shouldnt be needed, but fallback just in case
    case 'submittedForAssessment':
      return assessmentSteps[4];
    case 'Pre-Assessment':
      return assessmentSteps[5];
    case 'Assessing':
      return assessmentSteps[6];
    case 'Submitted':
      return assessmentSteps[7];
    case 'Assessment Complete Pending Remediation':
      return assessmentSteps[8];
    case 'Complete':
    case 'Completed':
      return assessmentSteps[10];
    case 'Rejected':
      return 'Rejected by Assessor';
    default:
      return status;
  }
  
};

export const surveyActionLabel = (type: string, status: string, shouldContinue: boolean) => {
  if (status === '-') return;

  if (status.toLowerCase() === 'pending' || status.toLowerCase() === 'self-attested') return `${shouldContinue ? 'Continue' : 'Begin'} ${type}`;
  if (status.toLowerCase() === 'complete') return SURVEY_ACTIONS_LABELS.reviewAndSubmit;
  if (status === 'Self-Reporting Complete') return SURVEY_ACTIONS_LABELS.scheduleAssessment;
  if (status.toLowerCase().indexOf('remediation') > -1) return SURVEY_ACTIONS_LABELS.viewRemediations;
  if (status.toLowerCase() === 'resolved') return SURVEY_ACTIONS_LABELS.viewQuestionnaire;
  return SURVEY_ACTIONS_LABELS.continueQuestionnaire;
};

const isThemeColor = (status: string) => {
  if (status === 'complete') return 'success';
  if (status === 'remediation') return 'error';
  if (status === 'submitted') return 'warning';
  if (status === 'cert') return 'primary';
  if (status === 'priority') return 'secondary';
  if (status === 'non-compliant') return 'nonCompliant';
  return (Object.keys(appTheme.palette).includes(status) ? status : 'primary') as PaletteKey;
};

export const surveyStatusColor = (survey?:Survey) => {
  if (!survey || survey.status === 'incomplete') return 'primary';
  let statusColor = 'primary';
  if (survey.status) return isThemeColor(survey.status);
  (survey.bestPracticeStatuses as BestPracticeStatus[])?.forEach(bestPracticeStatuses => {
    if (statuses.indexOf(bestPracticeStatuses.status) < statuses.indexOf(bestPracticeStatuses.status)) statusColor = bestPracticeStatuses.status;
  });
  return isThemeColor(statusColor);
};

export const assessmentActionLabel = (status?: string, user?: User) => {
  if (!user || !status) return { label: 'View Assessment', icon: <ViewIcon fontSize="small"/> };
  if (user.type === USER_TYPES.VENDOR_ADMIN) {
    if (status === 'Pre-Assessment') return { label: 'Update Assessment', icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: 'View and Comment', icon: <ChatIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: 'View and Comment', icon: <ChatIcon fontSize="small"/> };
    if (status === 'Remediation') return { label: 'Remediate', icon: <AutoFixNormalIcon fontSize="small"/> };
    if (status === 'Complete') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
  }

  if (user.type === USER_TYPES.ASSESSOR) {
    if (status === 'Pre-Assessment') return { label: 'Review and Comment', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: 'Assess', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: 'View and Comment', icon: <ChatIcon fontSize="small"/> };
  }

  if (user.type === USER_TYPES.CONTENT_OWNER) {
    if (status === 'Pre-Assessment') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Remediation') return { label: 'Review Findings and Set Priority', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Complete') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
  }

  if (user.type === USER_TYPES.TPN_ADMIN) {
    if (status === 'Pre-Assessment') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Assessing') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Submitted') return { label: 'Review and Comment', icon: <RateReviewIcon fontSize="small"/> };
    if (status === 'Remediation') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
    if (status === 'Complete') return { label: SURVEY_ACTIONS_LABELS.viewQuestionnaire, icon: <DynamicFormIcon fontSize="small"/> };
  }
  return { label: 'View Assessment', icon: <ViewIcon fontSize="small"/> };
};

export const getRemediationCounts = ({
  assessmentSurvey,
  themeProp,
} : {
  assessmentSurvey?: AssessmentSurvey;
  themeProp: Theme;
}) => {
  if (!assessmentSurvey) return 'Assessment Complete Pending Remediation';
  const remediationDisplayStatus = (assessmentSurvey.assessorAnswers as AssessorAnswer[]).some(it => ['remediation'].indexOf(it?.status || '') > -1 && !it.vendorStatus)
    ? 'Pending Remediation Plan'
    : 'Remediation Plan Complete';

  return (
    <>
      <Typography sx={{ color: themeProp.palette.customThemeColors?.darkGrey }}>Assessment Complete {remediationDisplayStatus}</Typography>
       <div style={{ width: '70px' }}>
        <BPRemediationCircles
          BPRemediationCount={assessmentSurvey.bestPracticeRemediationCount}
          IGRemediationCount={assessmentSurvey.implementationGuidanceRemediationCount}
        />
      </div>
    </>
  );
};

export const testSubmitAssessmentButton = (assessmentSurvey?: AssessmentSurvey, userType?: string) => {
  if (!assessmentSurvey) return;
  return (
    userType === USER_TYPES.ASSESSOR &&
    assessmentSurvey.status?.toLowerCase() === 'assessing' &&
    assessmentSurvey.assessorAnswers.length >= (assessmentSurvey.survey as Survey).questionAnswers.length
  );
};

export const getAssessmentQuestionsAndAnswersCount = (bpStatus: BPStatusById, assessmentQuestionsStatus?: AssessmentQuestionStatusById, questionnaire?: Questionnaire, wfStatus?: WorkflowStatus) => {
  if (!assessmentQuestionsStatus || !questionnaire) {
    return {
      questionsCount: 0,
      answersCount: 0,
      hasBP: false,
      hasQuestion: true,
      isComplete: false,
      actualAssessmentAnswers: [],
    };
  }

  const assAnsAr = Object.keys(assessmentQuestionsStatus).map((aqs) => assessmentQuestionsStatus[aqs]);
  const actualAssessmentAnswers = assAnsAr.filter(
    (assAns) => assAns.answer && assAns.answer.length > 0 && assAns.id,
  );

  let questionsCount = 0;
  let answersCount = 0;
  let hasBP = false;
  let hasQuestion = false;

  questionnaire.domains.forEach((d) => {
    d.topics.forEach((t) => {
      t.bestPractices.forEach((bp) => {
        if (!bpStatus[bp.id] || bpStatus[bp.id].status === 'hide') return;
        if (bpStatus[bp.id].status !== 'hide') hasBP = true;

        questionsCount += bpStatus[bp.id].visibleQuestionsCount;
        bp.questions.forEach((bpQ) => {
          const questionHasAnswer = actualAssessmentAnswers.find(
            (aaa) =>
              aaa.answer &&
              (typeof aaa.question === 'number' ? aaa.question === bpQ.id : aaa.question.id === bpQ.id),
          );
          answersCount += questionHasAnswer ? 1 : 0;
          hasQuestion = true;
        });
      });
    });
  });

  const assessmentQStatusArr = Object.values(assessmentQuestionsStatus);
  const qCount = assessmentQStatusArr.length > questionsCount && !isWorkflowInAssessmentRange(wfStatus) ? assessmentQStatusArr.length : questionsCount;
  return {
    questionsCount: qCount,
    answersCount,
    hasBP,
    actualAssessmentAnswers,
    hasQuestion,
    isComplete: questionsCount === answersCount || qCount < answersCount,
  };
};

export interface StartQuestionnaireModalData {
  canStart: boolean;
  data: string;
}

export const getQuestionnaireModalData = (
  status: WorkflowStatus,
  currentVersion: string,
  newVersion: string,
  hasRemediations?: boolean,
) : StartQuestionnaireModalData => {

  // Base case: Modal will not show if no data is set
  const modalController = {
    data: '',
    canStart: false,
  };
  const activeQuestionnaireStatuses = [
    WORKFLOW_STATUSES.INCOMPLETE,
    WORKFLOW_STATUSES.NON_COMPLIANT,
    WORKFLOW_STATUSES.COMPLETE,
    WORKFLOW_STATUSES.SUBMITTED_FOR_ASSESSMENT,
  ] as WorkflowStatus[];

  const inProgressStatuses = [
    WORKFLOW_STATUSES.PRE_ASSESSMENT,
    WORKFLOW_STATUSES.ASSESSING,
    WORKFLOW_STATUSES.SUBMITTED,
    WORKFLOW_STATUSES.REJECTED,
  ] as WorkflowStatus[];

  // Handle based on status
  if (activeQuestionnaireStatuses.includes(status)) {
    modalController.data = `You have a ${currentVersion} Questionnaire in progress. Starting a new ${newVersion} Questionnaire will close your previous ${currentVersion} Questionnaire and it can no longer be edited`;
    modalController.canStart = true;
  } else if (status === WORKFLOW_STATUSES.REMEDIATION || (status === WORKFLOW_STATUSES.FINAL_COMPLETE && hasRemediations)) {
    modalController.data = `You have a ${currentVersion} assessment with pending remediation(s). Starting a new ${newVersion} Questionnaire will close the previous one, and you will not be able to update remediations. This cannot be undone.`;
    modalController.canStart = true;
  } else if (status === WORKFLOW_STATUSES.ASSIGNED) {
    modalController.data = `You cannot start a ${newVersion} Questionnaire since you requested an Assessment for ${currentVersion}. After the ${currentVersion} Assessment is complete you will be able to start a new Questionnaire.`;
  } else if (inProgressStatuses.includes(status)) {
    modalController.data = `You cannot start a ${newVersion} Questionnaire since you have an Assessment in progress. After the Assessment is complete you will be able to start a new Questionnaire.`;
  }

  return modalController;
};

export const everyQuestionControlHasAnswer = (questionAnswer: QuestionAnswer, question: Question) : boolean => {
  const answersSet = new Set([
    ...(questionAnswer?.nonImplementedAnswers || []),
    ...(questionAnswer?.nonApplicableAnswers || []),
    ...(questionAnswer?.compensatingControlAnswers || []),
    ...(questionAnswer?.answer || []),
  ]);

  if (
    (question.type === 'single_select' || (question.bestPractice as BestPractice)?.slug?.toLowerCase()?.includes('baseline')) &&
    (questionAnswer?.answer || []).length > 0
  ) {
    return true;
  }

  return question.answers?.every(a => {
    const answerText = a.text || '';
    if (['Fully Implemented', 'Not Applicable', 'Not Implemented'].includes(answerText)) {
      return true; // Skip
    }
    return answersSet.has(a.id);
  });
};

export const generateDefaultSubRemediationsObject = (answers: Answer[]) :  { [key: number]: PIDetails } => {
  const defaultSubRemediationsObject = answers.reduce((acc, answer) => {
    if ([FULLY_IMPLEMENTED, NOT_APPLICABLE, NOT_IMPLEMENTED].includes(answer.text || '')) return acc;
    return {
      ...acc,
      [answer.id]: {
        answer: answer.id,
        comment: '',
        compensatingOrNotApplicable: 'not_implemented',
        isImplemented: false,
      },
    };
  }, {} as { [key: number]: PIDetails });

  return defaultSubRemediationsObject;
};
