import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useTheme } from '@mui/material';
import FilterMenu from './FilterMenu';
import { setBestPractice, setQuestion, setCompanyCertifications, setCompanyFromResponse, setTotalQuestionCount } from '../../app/store/questionnaireAnswerSlice';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SearchIcon from '@mui/icons-material/Search';
import LegendCard from './Legend';
import StandardDialog from '../Modals/StandardDialog';
import CompanyCertificationForm from '../Directory/Certifications/CompanyCertificationForm';
import { Badge, Company, Version } from '../../interfaces/users.interface';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { buildBestPracticeTitle } from '../BestPractices/BestPractice';
import { Domain } from '../../interfaces/domain.interface';
import { Topic } from '../../interfaces/topic.interface';
import ProgressCard from './Progress';
import AssessmentAuditLogSidebar from './AssessmentAuditLogSidebar';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
import { Survey } from '../../interfaces/survey.interface';
import BPRemediationCircles from '../Assessments/BPRemediationCircles';
import { appTheme } from '../Themes/light';
import moment from 'moment';
import { Question } from '../../interfaces/question.interface';
import CollapsibleListItem from './CollapsibleListItem';
import { REMEDIATION_OPTIONS, REMEDIATION_VERIFICATION_STATUS } from '../../services/surveyHelpers';
import { clearFilters, setFilteredQuestions } from '../../app/store/remediationFilterSlice';
import { remediationFilter } from '../../services/filters';
import { WORKFLOW_STATUSES } from '../../services/workflow';
import { USER_TYPES } from '../../services/user';

type RemediationWorkflowTypes = 'Rejected' | 'Assessing' | 'Submitted' | 'Remediation' | 'Complete';

const QuestionnaireSidebar = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { assessment } = useAppSelector(state => state.assessments);
  const { bpVerificationList } = useAppSelector((state) => state.assessments);
  const { filteredVerificationStatus, filteredPlanStatus, filteredPastDue, filteredQuestionType } = useAppSelector((state) => state.remediationFilterSlice);
  const { questionnaire, domain, topic, bestPractice, companyCerts, bpStatus, preview, company, assessmentStatus, assessmentQuestionsStatus } = useAppSelector(state => state.questionnaireAnswer);
  const { user } = useAppSelector(state => state.user);
  const { applicationVersions } = useAppSelector(state => state.serviceCategories);
  const [activeDomain, setActiveDomain] = useState(domain ? domain.id : domain);
  const [activeTopic, setActiveTopic] = useState(topic ? topic.id : topic);
  const [activeBestPractice, setActiveBestPractice] = useState(bestPractice ? bestPractice.id : bestPractice);
  const { survey } = useAppSelector(state => state.surveys);
  const { workflow } = useAppSelector(state => state.workflow);
  const blueBadge = useMemo(() => (workflow?.badges as Badge[])?.find(b => b.title === 'tpn_self_reported'), [workflow]);
  const [assessmentSurvey, setAssessmentSurvey] = useState<AssessmentSurvey | undefined>(undefined);

  const [remediationBPs, setRemediationBPs] = useState<BestPractice[]>([]);

  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  const handleUploadDocumentOpen = () => setUploadDocumentOpen(true);
  const handleUploadDocumentClose = () => setUploadDocumentOpen(false);

  const [expanded, setExpanded] = useState(false);
  const handleBPListExpandClick = () => {
    setExpanded(!expanded);
  };
  const [remediationOpen, setRemediationOpen] = useState(false);
  const handleExpandClick = () => {
    setRemediationOpen(!remediationOpen);
  };

  const companyCallback = (nCompany:Company) => {
    if (!survey || !nCompany.companyCertifications) return;
    const approvedCerts = nCompany.companyCertifications.filter(cert => cert.adminApproved && (!blueBadge || moment(cert.createdAt).unix() < moment(blueBadge.createdAt).unix()));

    let newCompanyCerts = !survey.application && !survey.site ?  approvedCerts : [];
    if (survey.application && survey.version && company?.companyApplications && applicationVersions.length) {
      const companyApplication = company.companyApplications.find(CA => CA.application.id === survey.application);
      if (!companyApplication) return;
      const version = (companyApplication.application.versions as Version[])?.find(v => v.value === survey.version);
      if (!version) return;
      approvedCerts.forEach(ccert => {
        if (ccert.applicationVersions?.some(av => {
          const cav = applicationVersions.find(AV => AV.id === av);
          if (!cav) return;
          return (cav.version as Version).id === version.id;
        })) newCompanyCerts.push(ccert);
      });
    }
    if (survey.site) {
      approvedCerts.forEach(ccert => {
        if (ccert.sites.some(app => app.id === survey.site)) newCompanyCerts.push(ccert);
      });
    }
    dispatch(setCompanyFromResponse(nCompany));
    dispatch(setCompanyCertifications(newCompanyCerts));
  };

  useEffect(() => {
    if (!assessment || !survey) { setAssessmentSurvey(undefined); return; }
    setAssessmentSurvey((assessment.surveys as AssessmentSurvey[]).find(assSur => (assSur.survey as Survey).id === survey.id));
  }, [ assessment, survey ]);

  useEffect(() => {
    if (!domain) return;
    setActiveDomain(domain.id);
  }, [domain]);
  useEffect(() => {
    if (!topic) return;
    setActiveTopic(topic.id);
  }, [topic]);
  useEffect(() => {
    if (!bestPractice) return;
    setActiveBestPractice(bestPractice.id);
  }, [bestPractice]);

  const typographyProps = useCallback((id: number) => {
    let fontWeight = '100';
    let color = '#000';
    if (id === activeBestPractice) fontWeight = 'bold';
    
    if (bpStatus && bpStatus[id] && bpStatus[id].color && bpStatus[id].color !== 'info') {
      color = theme.palette[bpStatus[id].color].main;
    }

    if (bpStatus[id].status === 'remediation' || bpStatus[id].status === 'submitted') {
      const tmpColor = bpStatus[id].bestPractice.questions.reduce((acc, q) => {
        if (assessmentQuestionsStatus && assessmentQuestionsStatus[q.id] && [REMEDIATION_OPTIONS.willRemediateLater, REMEDIATION_OPTIONS.willNotRemediate].includes(assessmentQuestionsStatus[q.id].vendorStatus || '')) {
          acc = theme.palette.nonCompliant.main;
        } else if (!acc && assessmentQuestionsStatus && assessmentQuestionsStatus[q.id]) {
          if (!assessmentQuestionsStatus[q.id].vendorStatus && assessmentQuestionsStatus[q.id].status === 'remediation') {
            acc = theme.palette.error.main;
          } else if (assessmentQuestionsStatus[q.id].vendorStatus === REMEDIATION_OPTIONS.remediated) {
            if (assessmentQuestionsStatus[q.id].verificationStatus === REMEDIATION_VERIFICATION_STATUS.reviewed || (assessmentQuestionsStatus[q.id].remediationQuestionAnswers?.length && assessmentQuestionsStatus[q.id].remediationQuestionAnswers?.every(rqa => rqa.verificationStatus === REMEDIATION_VERIFICATION_STATUS.reviewed))) {
              acc = theme.palette.primary.main;
            }
          }
        }
        return acc;
      }, '');
      color = tmpColor || theme.palette.nonCompliant.main;
    }
    
    return { color, fontWeight };
  }, [activeBestPractice, bpStatus, theme.palette.nonCompliant.main, theme.palette]);

  useEffect(() => {
    if (!bpStatus || Object.keys(bpStatus).length === 0) return;

    const newBPs:Record<string, BestPractice> = {};
    const filteredQuestionSet = new Set<number>();
    if (questionnaire?.domains.length) {
      questionnaire.domains.forEach((domainItem) => {
        domainItem.topics.forEach((topicItem) => {
          topicItem.bestPractices.forEach((bestPracticeItem) => {
            const newBP = bpStatus[bestPracticeItem.id];
            if (newBP.status !== 'hide' || newBP && ['priority'].indexOf(newBP.status) > -1) {
              newBP.bestPractice.questions.reduce((acc, it) => {
                if (assessmentQuestionsStatus && assessmentQuestionsStatus[it.id] && assessmentQuestionsStatus[it.id].status !== 'assessorApproved') {
                  if (filteredVerificationStatus || filteredQuestionType || filteredPlanStatus || filteredPastDue) {
                    const isValidFilteredQuestion = remediationFilter(
                      filteredVerificationStatus,
                      filteredQuestionType,
                      filteredPlanStatus,
                      filteredPastDue,
                      it,
                      assessmentQuestionsStatus[it.id],
                      bpVerificationList,
                    );
                    if (!isValidFilteredQuestion) return acc;

                    newBPs[newBP.bestPractice.id] = newBP.bestPractice;
                    acc[newBP.bestPractice.id] = true;
                    filteredQuestionSet.add(it.id);
                  } else {
                    newBPs[newBP.bestPractice.id] = newBP.bestPractice;
                    acc[newBP.bestPractice.id] = true;
                  }
                }
                return acc;
              }, {} as { [key: number]: boolean });
            }
          });
        });
      });
    }

    const newBPArray: BestPractice[] = [...Object.values(newBPs)].sort((a, b) => {
      if ((a.domain as Domain)?.id === (b.domain as Domain)?.id) {
        return (a.topic as Topic)?.id - (b.topic as Topic)?.id;
      } else {
        return a.id - b.id;
      }
    });
    setRemediationBPs(newBPArray);
    let bpVisibleCountQuestionCount = 0;
    questionnaire?.domains.forEach(tdomain => {
      tdomain.topics.forEach(ttopic => {
        ttopic.bestPractices.forEach(tbp => {
          if (bpStatus[tbp.id].status !== 'hide') bpVisibleCountQuestionCount += bpStatus[tbp.id].visibleQuestionsCount;
        });
      });
    });
    dispatch(setFilteredQuestions(Object.fromEntries(Array.from(filteredQuestionSet).map(qId => [qId, true]))));
    dispatch(setTotalQuestionCount(bpVisibleCountQuestionCount));
  }, [bpStatus, questionnaire?.domains, filteredVerificationStatus, filteredPlanStatus, filteredPastDue, bpVerificationList, filteredQuestionType]);

  const handelUploadCertification = () => {
    if (!user || user.type === USER_TYPES.TPN_ADMIN) return;

    handleUploadDocumentOpen();
  };

  const canShowDomain = (tdomain:Domain) => {
    let show = false;
    let x = 0;
    let y = 0;
    while (!show) {
      const curBP = tdomain.topics[x].bestPractices[y];
      if (bpStatus[curBP.id].status !== 'hide' && bpStatus[curBP.id].visibleQuestionsCount !== 0) show = true;
      if (x === tdomain.topics.length - 1 && y === tdomain.topics[x].bestPractices.length - 1) break;
      if (y === tdomain.topics[x].bestPractices.length - 1) {
        y = 0;
        x++;
      } else {
        y++;
      }
    }
    return show;
  };

  const canShowTopic = (ttopic:Topic) => {
    let show = false;
    let y = 0;
    while (!show) {
      const curBP = ttopic.bestPractices[y];
      if (bpStatus[curBP.id].status !== 'hide' && bpStatus[curBP.id].visibleQuestionsCount !== 0) show = true;
      if (y === ttopic.bestPractices.length - 1) break;
      y++;
    }
    return show;
  };

  const handleBPClick = useCallback((bp:BestPractice) => {
    const hasQuestions = bp.questions.findIndex(q => typeof q === 'number');
    if (hasQuestions !== -1) return;

    setActiveBestPractice(bp.id);
    dispatch(setBestPractice({ ...bp, domain: typeof bp.domain === 'number' ? bp.domain : bp.domain?.id, topic: typeof bp.topic === 'number' ? bp.topic : bp.topic?.id }));
    dispatch(setQuestion(bp.questions[0]));
  }, [dispatch, setActiveBestPractice]);

  const bpRemediationCounts = (remediationBP: BestPractice) => {
    let BPRemediationCount = 0;
    let IGRemediationCount = 0;
    remediationBP.questions.forEach(bpq => {
      const qId:number = typeof bpq === 'number' ? bpq : bpq.id;
      if (assessmentQuestionsStatus && (['remediation', 'priority', 'submitted'].includes(assessmentQuestionsStatus[qId]?.status || '')) ) {
        if (bpq.isBestPractice) {
          BPRemediationCount++;
        } else {
          IGRemediationCount++;
        }
      }
    });
    return { BPRemediationCount, IGRemediationCount };
  };

  const findFirstUnansweredQuestion = () => {
    if (!questionnaire || !bpStatus || !Object.keys(bpStatus).length) {
      return;
    }

    let found = false;
    let unAnsweredQuestion = {
      domain: 0,
      topic: 0,
      bestPractice: undefined as BestPractice | undefined,
    };
    
    questionnaire.domains.forEach(qDomain => {
      if (found || (!preview && !canShowDomain(qDomain))) {
        return;
      }
      
      qDomain.topics.forEach(dTopic => {
        if (found || (!preview && !canShowTopic(dTopic))) {
          return;
        }

        dTopic.bestPractices.forEach(tBP => {
          if (found || bpStatus[tBP.id].status === 'hide' || bpStatus[tBP.id].visibleQuestionsCount === 0 || !bpStatus[tBP.id].firstUnanswered) {
            return;
          }

          found = true;
          unAnsweredQuestion = {
            domain: qDomain.id,
            topic: dTopic.id,
            bestPractice: tBP,
          };
        });
      });
    });

    if (found && unAnsweredQuestion) {
      setActiveDomain(unAnsweredQuestion.domain);
      setActiveTopic(unAnsweredQuestion.topic);
      setActiveBestPractice(unAnsweredQuestion.bestPractice?.id);
      dispatch(setBestPractice({ 
        ...unAnsweredQuestion.bestPractice!,
        domain: typeof unAnsweredQuestion.bestPractice!.domain === 'number' ? unAnsweredQuestion.bestPractice!.domain : unAnsweredQuestion.bestPractice!.domain?.id,
        topic: typeof unAnsweredQuestion.bestPractice!.topic === 'number' ? unAnsweredQuestion.bestPractice!.topic : unAnsweredQuestion.bestPractice!.topic?.id,
      }));
      dispatch(setQuestion(bpStatus[unAnsweredQuestion.bestPractice!.id].firstUnanswered!));
    }
  };

  const findFirstUnansweredAssessment = () => {
    if (!assessmentQuestionsStatus || !questionnaire || !bpStatus || !Object.keys(bpStatus).length) {
      return;
    }

    const assAnsAr = Object.keys(assessmentQuestionsStatus).map((aqs) => assessmentQuestionsStatus[aqs]);
    const actualAssessmentAnswers = assAnsAr.filter(
      (assAns) => assAns.answer && assAns.answer.length > 0 && assAns.id,
    );

    let found = false;
    let unAnsweredAssessment = {
      domain: 0,
      topic: 0,
      bestPractice: undefined as BestPractice | undefined,
      question: undefined as Question | undefined,
    };
    
    questionnaire.domains.forEach(qDomain => {
      if (found || (!preview && !canShowDomain(qDomain))) {
        return;
      }
      
      qDomain.topics.forEach(dTopic => {
        if (found || (!preview && !canShowTopic(dTopic))) {
          return;
        }

        dTopic.bestPractices.forEach(tBP => {
          if (found || bpStatus[tBP.id].status.toLowerCase() === 'hide' || bpStatus[tBP.id].visibleQuestionsCount === 0) {
            return;
          }

          tBP.questions.forEach(bpQ => {
            if (found) return;

            const questionHasAnswer = actualAssessmentAnswers.find(
              (aaa) =>
                aaa.answer &&
                (typeof aaa.question === 'number' ? aaa.question === bpQ.id : aaa.question.id === bpQ.id),
            );

            if (!questionHasAnswer) {
              found = true;
              unAnsweredAssessment = {
                domain: qDomain.id,
                topic: dTopic.id,
                bestPractice: tBP,
                question: bpQ,
              };
            }
          });
        });
      });
    });

    if (found && unAnsweredAssessment) {
      setActiveDomain(unAnsweredAssessment.domain);
      setActiveTopic(unAnsweredAssessment.topic);
      setActiveBestPractice(unAnsweredAssessment.bestPractice?.id);
      dispatch(setBestPractice({ 
        ...unAnsweredAssessment.bestPractice!,
        domain: typeof unAnsweredAssessment.bestPractice!.domain === 'number' ? unAnsweredAssessment.bestPractice!.domain : unAnsweredAssessment.bestPractice!.domain?.id,
        topic: typeof unAnsweredAssessment.bestPractice!.topic === 'number' ? unAnsweredAssessment.bestPractice!.topic : unAnsweredAssessment.bestPractice!.topic?.id,
      }));
      dispatch(setQuestion(unAnsweredAssessment.question!));
    }
  };

  const handleProgressBarClick = () => {
    if (assessment) {
      findFirstUnansweredAssessment();
    } else {
      findFirstUnansweredQuestion();
    }
  };

  const showRemediationMenu = useMemo(() => {
    if (!workflow) return false;
    const wfStatus = workflow.status as RemediationWorkflowTypes;
    return [
      WORKFLOW_STATUSES.ASSESSING,
      WORKFLOW_STATUSES.SUBMITTED,
      WORKFLOW_STATUSES.REJECTED,
      WORKFLOW_STATUSES.REMEDIATION,
      WORKFLOW_STATUSES.FINAL_COMPLETE,
    ].includes(wfStatus);
  }, [workflow]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);

  return (
    <>
    {assessmentSurvey && !assessmentSurvey.reportIssueDate && <AssessmentAuditLogSidebar assessmentSurvey={assessmentSurvey} assessment={assessment!.id!} />}
    <ProgressCard onProgressBarClick={handleProgressBarClick} />
    { showRemediationMenu &&
      <Paper sx={{ mb: 1 }}>
        <List>
          <ListItemButton onClick={handleExpandClick}>
          <ListItemText 
              primary={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <strong style={{ color: theme.palette.customThemeColors?.darkGrey }}>
                    Control{remediationBPs.length > 1 ? 's' : ''}
                    {' in Remediation:'}
                  </strong>
                  <div style={{ marginLeft: '5px' }}>
                    View {remediationBPs.length}
                  </div>
                </div>
              }
            />
            {remediationOpen ? <ExpandLess /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={remediationOpen} timeout="auto" unmountOnExit>
            <FilterMenu />
            <List disablePadding>
            { remediationBPs.map((curBestPractice, x) => (
               <Box key={x} sx={{ position: 'relative' }}>
	                 <Box sx={{     position: 'absolute',
	                   right: appTheme.spacing(1),
	                   top: '50%',
	                   transform: 'translateY(-50%)',
	                   display: 'grid',
	                   gap: '10px' }}>
                   <BPRemediationCircles { ...bpRemediationCounts(curBestPractice) } />
                 </Box>
                 <CollapsibleListItem
                  pr={7}
                  isParentRemediation={true}
                  bpStatus={bpStatus}
                  typographyProps={typographyProps}
                  item={curBestPractice}
                  titleOverride={buildBestPracticeTitle(curBestPractice)}
                  setActive={() => {return false;}} active={activeBestPractice}
                  idx={x}
                  cantCollapse={true}
                  onClick={handleBPClick.bind(null, curBestPractice)}
                  pl={1}
                />
               </Box>
            ))}
            {remediationBPs.length === 0 && (
                <ListItem
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '10px 20px',
                    marginTop: '5px',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '4px',
                  }}
                >
                  <ListItemText
                    primary="No Controls in Remediation found"
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'text.secondary',
                      fontWeight: 400,
                    }}
                  />
                </ListItem>
            )}
            </List>
          </Collapse>
        </List>
      </Paper>
    }
    <Paper>
      {questionnaire && bpStatus && Object.keys(bpStatus).length > 0 && <>
        <List>
          <ListItemButton onClick={handleBPListExpandClick}>
          <ListItemText primary={
               <span style={{ color: theme.palette.customThemeColors?.darkGrey }}>
                 <strong>Current Best Practice:</strong>
                 {` ${bestPractice ? bestPractice.title : 'Loading'}`}
               </span>
             }
	           />
            {expanded ? <ExpandLess /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List
              component="nav"
              aria-labelledby="questionnaire-subheader"
            >
              {questionnaire.domains.map((curDomain, i) => ( (canShowDomain(curDomain) || preview) &&
                <CollapsibleListItem bpStatus={bpStatus} typographyProps={typographyProps} key={curDomain.id + curDomain.slug} item={curDomain} setActive={() => setActiveDomain(curDomain.id)} active={activeDomain} idx={i + 1}>
                  <List component="div" disablePadding>
                    { curDomain.topics.map((curTopic, d) => ( (canShowTopic(curTopic) || preview) &&
                      <CollapsibleListItem bpStatus={bpStatus} typographyProps={typographyProps} key={curTopic.id + curTopic.slug} item={curTopic} setActive={() => setActiveTopic(curTopic.id)} active={activeTopic} idx={d + 1} pl={2}>
                        <List component="div" disablePadding>
                          { curTopic.bestPractices.map((curBestPractice, x) => ( bpStatus[curBestPractice.id].status !== 'hide' && bpStatus[curBestPractice.id].visibleQuestionsCount !== 0 &&
                            <CollapsibleListItem bpStatus={bpStatus} typographyProps={typographyProps} key={curBestPractice.id + curBestPractice.slug} item={curBestPractice} setActive={() => {return false;}} active={activeBestPractice} idx={x} cantCollapse={true} onClick={() => handleBPClick(curBestPractice)} pl={4}/>
                          ))}
                        </List>
                      </CollapsibleListItem>
                    ))}
                  </List>
                  { (i + 1) !== questionnaire.domains.length && <Divider /> }
                </CollapsibleListItem>
              ))}
            </List>
          </Collapse>
        </List>
        {((!assessmentStatus || assessmentStatus === 'Pre-Assessment') || (companyCerts && companyCerts.length > 0)) && <Box sx={{ pb: 2 }}>
          <Divider sx={{ mb: 2 }} />
          { companyCerts && companyCerts.length > 0 && <>
          <Typography component="div" pl={2} pr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}><Box>Certifications:</Box><Box>End Date:</Box></Typography>
            <List>
              {companyCerts.map((certification) => (
                <a key={certification.id} href={certification.document} target='_blank' rel="noreferrer">
                  <ListItem>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}><SearchIcon /> {certification.certification.title}</Box>
                      <Box>{Moment(certification.expirationDate).format('MM/DD/YYYY')}</Box>
                    </Box>
                  </ListItem>
                </a>
              ))}
            </List>
          </>}
          {user && user.type === USER_TYPES.VENDOR_ADMIN && (!assessmentStatus || assessmentStatus === 'Pre-Assessment') && <Button variant="contained" sx={{ margin: 2, mb: 0, width: 'calc(100% - 32px)' }} startIcon={<UploadFileIcon />} onClick={() => handelUploadCertification()}>Upload Certification</Button>}
        </Box>}
      </>}
    </Paper>
    <LegendCard mt={1} />
    {company && user && user.type === USER_TYPES.VENDOR_ADMIN &&
      <StandardDialog title="Certification Upload" handleClose={handleUploadDocumentClose} isOpen={uploadDocumentOpen}>
        <CompanyCertificationForm company={company} handleClose={() => { handleUploadDocumentClose(); }} uploadCallback={companyCallback} site={survey ? survey.site : undefined} application={survey ? survey.application : undefined} />
      </StandardDialog>
    }
    </>
  );
};

export default QuestionnaireSidebar;
