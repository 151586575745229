// react and external libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
// MUI
import { FormControlLabel, FormGroup, Stack, Switch, Tooltip, Typography } from '@mui/material';
// redux
import { useAppSelector } from '../../../app/hooks';
// interface
import { SiteCloudStatus, Survey } from '../../../interfaces/survey.interface';
import { Assessment, AssessmentSurvey } from '../../../interfaces/assessment.interface';
import { Company, CompanyApplication, Contact, Version } from '../../../interfaces/users.interface';
// services
import { updateAssessmentSurvey, getSiteCloudStatus } from '../../../services/assessments';
// components
import WorkflowStatus from '../WorkflowStatus';
import WorkflowButtons from '../WorkflowButtons';
import { DataCell, DataRow } from '../../Tables/DataList';
import SiteSidebar from '../Sites/SitesAccordion/SiteSidebar';
import VersionSidebar from '../Applications/CompanyApplicationsAccordion/InhouseApplications/VersionSidebar';
import { USER_TYPES } from '../../../services/user';

type AssessmentSurveyRowProps = {
  company: Company;
  assessment: Assessment;
  survey: Survey;
  assessmentSurvey: AssessmentSurvey;
  site?: Contact;
  companyApplication?: CompanyApplication;
  updateAssessmentFromAssessmentSurvey: (assessmentSurvey: AssessmentSurvey) => void;
};

const AssessmentSurveyRow = (props: AssessmentSurveyRowProps) => {
  const {
    assessment,
    company,
    survey,
    assessmentSurvey,
    site,
    companyApplication,
    updateAssessmentFromAssessmentSurvey,
  } = props;
  // router and redux
  const { user } = useAppSelector((state) => state.user);
  const { workflows } = useAppSelector((state) => state.workflow);
  // states
  const [isCloud, setisCloud] = useState(assessmentSurvey.isCloud);
  const [isInPerson, setisInPerson] = useState(assessmentSurvey.isInPerson);
  const [isOnPremise, setIsOnPremise] = useState(survey.site ? true : assessmentSurvey.isOnPremise);
  const [isVersionSidebarOpen, setIsVersionSidebarOpen] = useState(false);
  const [isSiteSidebarOpen, setIsSiteSidebarOpen] = useState(false);
  // handler functions
  const handleSiteClick = useCallback(() => {
    setIsSiteSidebarOpen(true);
  }, [site]);
  const handleSiteSidebarClose = useCallback(() => setIsSiteSidebarOpen(false), []);
  const handleApplicationClick = useCallback(() => {
    setIsVersionSidebarOpen(true);
  }, [companyApplication]);
  const handleVersionSidebarClose = useCallback(() => setIsVersionSidebarOpen(false), []);
  // local variables
  const activeWorkflows = useMemo(
    () => workflows?.filter((wf) => wf.isActive && wf.assessmentSurvey === assessmentSurvey.id),
    [workflows, assessmentSurvey.id],
  );

  const workflow = useMemo(() => (activeWorkflows && activeWorkflows.length > 0 ? activeWorkflows[0] : undefined), [activeWorkflows]);
  const primaryContactDisplay = useMemo(
    () => (site?.primaryContact ? `${site?.primaryContact.firstName} ${site?.primaryContact.lastName}` : ''),
    [site?.primaryContact],
  );
  const [siteCloudStatus, setSiteCloudStatus] = useState<SiteCloudStatus>({ site: false, cloud: false, both: false });

  const fetchSiteCloudStatus = async (baselineId: number) => {
    const siteCloundData = await getSiteCloudStatus(baselineId);
    setSiteCloudStatus(siteCloundData);
  };

  useEffect(() => {
    if (workflow?.baseline) fetchSiteCloudStatus(workflow.baseline);
  }, [workflow?.baseline]);

  const primaryContactEmail = useMemo(
    () => (site?.primaryContact ? site?.primaryContact.email : ''),
    [site?.primaryContact],
  );
  const primaryContactPhone = useMemo(
    () => (site?.primaryContact ? site?.primaryContact.contact?.phoneNumber : ''),
    [site?.primaryContact],
  );
  const primaryContactAddress = useMemo(
    () => {
      if (!site?.primaryContact || !site?.address) return '';

      return <>
        {site?.address}
        {site?.address2 ? ` ${site?.address2}, ` : ' '}
        {site?.city ? `${site?.city}, ` : ''}
        {site?.state && site.state.length > 0 ? `${site?.state}, ` : ''}
        {site?.country ? `${site?.country} ` : ''}
        {site?.zipcode || ''}
      </>;
    },
    [site],
  );
  const tooltipTitle = site ? <>
    {site.name}
    {primaryContactDisplay}
    {primaryContactPhone ?  ` - ${primaryContactPhone}` : ''}
    {primaryContactEmail ? ` - ${primaryContactEmail}` : ''}
    {primaryContactAddress ? <><br/>{primaryContactAddress}</> : ''}
  </> : '';
  const name = useMemo(
    () =>
      site ? (
        <Tooltip title={tooltipTitle}>
          <Typography onClick={handleSiteClick} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '1.2em', cursor: 'pointer', '&:hover': { color: 'purple', textDecoration: 'underline' } }}>
            {site.name}
          </Typography>
        </Tooltip>
      ) : companyApplication ? (
        <Typography onClick={handleApplicationClick} sx={{ fontSize: '1.2em', cursor: 'pointer', '&:hover': { color: 'purple', textDecoration: 'underline' } }}>{companyApplication.application.name}</Typography>
      ) : (
        <Typography sx={{ fontSize: '1.2em' }}>Name Missing</Typography>
      ),
    [site, primaryContactDisplay, primaryContactEmail, primaryContactPhone, companyApplication],
  );
  const type = useMemo(
    () => (site ? 'Site' : companyApplication ? 'Application' : 'Type Missing'),
    [site, companyApplication],
  );

  // updating assessment survey
  useEffect(() => {
    if (
      isOnPremise !== assessmentSurvey.isOnPremise ||
      isCloud !== props.assessmentSurvey.isCloud ||
      isInPerson !== props.assessmentSurvey.isInPerson
    ) {
      updateAssessmentSurvey({ ...props.assessmentSurvey, isOnPremise, isCloud, isInPerson }).then(
        (nAssessmentSurvey) => updateAssessmentFromAssessmentSurvey(nAssessmentSurvey),
      );
    }
  }, [isOnPremise, isCloud, isInPerson]);

  // initial set value on assessmentSurvey props has value
  useEffect(() => {
    setIsOnPremise(assessmentSurvey.isOnPremise);
    setisCloud(assessmentSurvey.isCloud);
    setisInPerson(assessmentSurvey.isInPerson);
  }, [assessmentSurvey]);

  return (
    <>
      <DataRow>
        <DataCell xs={2}>{type}</DataCell>
        <DataCell xs={2}>{name}</DataCell>
        <DataCell xs={2}>
          <Stack spacing={2} justifyContent='flex-start' alignItems='flex-start'>
            <FormGroup>
              <FormControlLabel
                labelPlacement='start'
                disabled
                control={<Switch checked={siteCloudStatus.site || siteCloudStatus.both} />}
                label={workflow?.site ? 'Site' : 'On-Prem'}
              />
              <FormControlLabel
                labelPlacement='start'
                disabled
                control={<Switch checked={siteCloudStatus.cloud || siteCloudStatus.both} />}
                label='Cloud'
              />
            </FormGroup>
          </Stack>
        </DataCell>
        <DataCell xs={2}>
          <Stack spacing={2} justifyContent='flex-start' alignItems='flex-start'>
            <FormGroup>
              <FormControlLabel
                labelPlacement='start'
                disabled={
                  user?.type === USER_TYPES.ASSESSOR ||
                  (['Assigned', 'Rejected'].indexOf(assessment.status) === -1 && user?.type !== USER_TYPES.TPN_ADMIN)
                }
                control={<Switch checked={isInPerson} onChange={(e, v) => setisInPerson(v)} />}
                label='On Site'
              />
            </FormGroup>
          </Stack>
        </DataCell>
        <DataCell xs={2}>
          {assessment.dateAccepted && (
            <Typography variant='caption' sx={{ display: 'block' }}>
              Date Accepted: {moment(assessment.dateAccepted).format('MM/DD/YYYY')}
            </Typography>
          )}
          <WorkflowStatus company={company} workflow={workflow} survey={survey} assessmentSurvey={assessmentSurvey} />
        </DataCell>
        <DataCell xs={2} textAlign='center'>
          <WorkflowButtons company={company} workflow={workflow} survey={survey} assessmentSurvey={assessmentSurvey} />
        </DataCell>
      </DataRow>
      <SiteSidebar
        isOpen={isSiteSidebarOpen}
        company={company}
        site={site || null}
        isAssessment={true}
        onClose={handleSiteSidebarClose}
      />
      <VersionSidebar
        isOpen={isVersionSidebarOpen}
        company={company}
        companyApp={companyApplication}
        application={companyApplication?.application}
        version={workflow?.version as Version}
        isAssessment={true}
        onClose={handleVersionSidebarClose}
      />
    </>
  );
};

export default AssessmentSurveyRow;
