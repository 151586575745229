import React, { useEffect } from 'react';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearState, fetchUserDataByToken, setShowTokenExpiredModal } from '../../app/store/userSlice';
import { Authenticator, View, useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { refreshAuthSession } from '../../services/auth';
import { USER_TYPES } from '../../services/user';

export default function Login(props: { userType: string, initialState: 'signIn' | 'signUp' | 'resetPassword' | undefined }) {
  const {
    user,
  } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const { toSignIn, toSignUp, toResetPassword, route } = useAuthenticator((context) => [context.toSignIn, context.toSignUp, context.toResetPassword, context.route]);
  let envString: string = process.env.REACT_APP_ENVIRONMENT ? ` ${process.env.REACT_APP_ENVIRONMENT}` : '';
  if (envString == ' PROD') envString = ''; // Amplify env vars must have a value set. Remove value from `totpIssuer` string.
  const formFields = {
    setupTOTP: {
      QR: {
        totpIssuer: `Trusted Partner Network${envString}`,
      },
    },
    signUp: {
      given_name: {
        label: 'First Name',
        placeholder: 'First Name',
        isRequired: true,
        order: 1,
      },
      family_name: {
        label: 'Last Name',
        placeholder: 'Last Name',
        isRequired: true,
        order: 2,
      },
      email: {
        order: 3,
      },
      'custom:phone_number': {
        label: 'Phone',
        placeholder: 'Phone Number',
        isRequired: true,
        order: 4,
      },
      password: {
        order: 5,
      },
      confirm_password: {
        order: 6,
      },
    },
  };

  useEffect(() => {
    if (props.initialState === 'signIn') toSignIn();
    if (props.initialState === 'signUp') toSignUp();
    if (props.initialState === 'resetPassword') toResetPassword();
  }, [props.initialState]);

  const nav = useNavigate();
  useEffect(() => {
    dispatch(setShowTokenExpiredModal(false));

    const listener = async (data: any) => {
      let event = data.payload.event;
      // Ensure fresh session after sign-in
      if (event === 'signIn') {
        await refreshAuthSession();
      }

      if (event === 'signIn' || event === 'signUp') {
        dispatch(fetchUserDataByToken());
      }
    };
 
    Hub.listen('auth', listener);

    // Cleanup listener when component unmounts or re-renders
    return () => {
      Hub.remove('auth', listener);
    };
  }, [dispatch]);

  useEffect(() => {
    if (route !== 'authenticated' || !user) return;
    dispatch(clearState());
    let navTo = '';
    if (user.type !== USER_TYPES.TPN_ADMIN && user.companies.length < 1) {
      navTo  = '/company-onboarding';
    } else if (user.type == USER_TYPES.TPN_ADMIN) {
      navTo = '/registry';
    } else {
      navTo = '/profile';
    }
    nav(navTo, { replace: true });
  }, [route, user, nav]);

  return (
    <Container component="main">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <View className="auth-wrapper">
          <Authenticator
            className="custom-authenticator"
            initialState={props.initialState}
            formFields={formFields}
            components={{
              SignUp: {
                FormFields() {
                  return (
                    <>
                      {props.userType && <input name='custom:userType' value={props.userType} hidden onChange={(event) => event.preventDefault()}/>}
                      <Authenticator.SignUp.FormFields/>
                    </>
                  );
                },
              },
              ConfirmSignUp: {
                Header() {
                  return (
                    <Heading  level={5}>
                      Enter Confirmation Code:
                    </Heading>
                  );
                },
              },
              SetupTOTP: {
                Header() {
                  return (
                    <>
                    <Heading level={3} textAlign='center'>
                      Please Confirm One-Time Code
                    </Heading>
                    <Box sx={{
                      position: 'relative', // This makes it a positioning context
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: '25px auto', 
                      marginBottom: '45px',
                      textAlign: 'center',
                    }}>
                    <p style={{
                      position: 'absolute', 
                      width: '580px', 
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}>
                      Open your Authenticator app and scan the QR code below. Tap the &apos;+&apos; symbol to start the scanner. This securely links your TPN+ account for authentication.
                    </p>
                  </Box>
                </>
                  );
                },
              },
            }}
          >
          </Authenticator>
        </View>
      </Box>
    </Container>
  );
}

Login.defaultProps = {
  userType: 'vendor',
  initialState: 'signIn',
};
